import Card from "../../Utils/Card";
import Button from "../../Utils/Button";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {getGroupByManager} from "../../Services/group";
import {forgotPassword} from "../../Services/user";
import Modal from "../../Utils/Modal";
import Loading from "../../Utils/Loading";


export default function UserInformation(props) {
  const {user, admin, me} = props;
  const [groups, setGroups] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) {
      return;
    }
    getGroupByManager(user.id).then((json) => {
      setGroups(json);
    });
  }, [user]);

  if (!user) {
    return null;
  }

  if (loading)
    return <Loading page/>;

  return (
    <Card className={"p-6 flex flex-col gap-4 xl:flex-row"}>
      <div className={"flex gap-2 flex-col xl:w-1/2"}>
        <h2 className={"text-xl font-medium text-indigo-600"}>{user.fname} {user.lname}</h2>
        <p>
          Email: <a className={"underline"} href={`mailto:${user.email}`}>{user.email}</a>
        </p>
        <p>
          Role(s): {user.roles && user.roles.map((role) => role.replace("ROLE_", "")).join(", ")}
        </p>
        <p>
          Groupe: <b>{user.inGroup !== null ? <Link to={"/group/" + user.inGroup?.id} className={"underline"}>
          {user.inGroup.name} </Link>: "Aucun" }</b>
        </p>
        {groups?.length > 0 && <p>
          Groupes gérés: {groups.map((group) => <Link key={group.id} to={"/group/" + group.id} className={"underline font-bold"}>
          {group.name}</Link>).reduce((prev, curr) => [prev, ", ", curr])}
        </p>}
      </div>
      <div className={"flex justify-center h-full gap-4 xl:gap-6 xl:justify-center flex-col xl:w-1/2"}>
        {!me && !user.enabled && <p className={"text-red-500 bg-red-50 px-3 py-2 rounded-lg border border-red-500 w-full lg:w-1/2"}>Compte désactivé</p>}
        {admin && <Link to={"/user/edit/" + user.id} className={"w-full lg:w-1/2 h-10"}><Button className={"w-full h-10"}>Modifier</Button></Link>}
        {me && <Button className={"w-full lg:w-1/2 h-10"} onClick={() => {
          setLoading(true);
          forgotPassword(user.email).then((resp) => {
              if (resp)
                setShowModal(true);
              setLoading(false);
          });
        }}>Changer le mot de passe</Button>}
        <Modal show={showModal} setShow={setShowModal}>
          <Card className={"p-6 flex flex-col gap-4 w-96"}>
            <h2 className={"text-xl font-medium text-indigo-600"}>Email envoyé</h2>
            <p>Un email a été envoyé à {user.email} pour changer son mot de passe</p>
            <p className={"text-sm text-gray-500"}>Le lien n'est valide que pendant 1h</p>
            <Button onClick={() => setShowModal(false)}>Fermer</Button>
          </Card>
        </Modal>
      </div>
    </Card>
  );
}
