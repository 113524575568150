import useMonth from "../../Hooks/useMonth";
import LeaveCell from "./Cell";
import LeaveDay from "./LeaveDay";
import {Link} from "react-router-dom";

export function isWeekend(day) {
  return day.getDay() === 0 || day.getDay() === 6;
}
export default function UserRow(props) {
  const {leaves, user, month, hover, setHover, holidays} = props;
  const name = user.fname + " " + user.lname;
  const days = useMonth(month);

  if (!days)
    return null;

  return (
    <tr className={"hover:bg-indigo-100"}>
      <td className={"hover:bg-indigo-200 py-0.5 px-0.5 md:py-1 md:px-2 text-gray hover:text-black " + (hover === -1 ? "bg-indigo-100" : "")}
          onMouseEnter={() => setHover(-1)}
          onMouseLeave={() => setHover(null)}
      >
        <Link to={`/user/${user.id}`} className={"hover:underline"}>
          <p className={"text-sm-600 text-gray whitespace-nowrap"}>{name}</p>
        </Link>
      </td>
      {days.map((day, key) => {
        return (
          <LeaveCell id={key} key={key} day={day} hover={hover} setHover={setHover} holidays={holidays}>
            <div className={"flex"}>
              <LeaveDay day={day} leaves={leaves}/>
            </div>
          </LeaveCell>
        );
      })}
    </tr>
  );
}