import SideBar from "Layouts/SideBar";
import {useEffect, useState} from "react";
import Card from "Utils/Card";
import UserInformation from "Components/Profile/UserInformation";
import UserLeaves from "Components/Profile/UserLeaves";
import UserFutureLeaves from "Components/Profile/UserFutureLeaves";
import {useParams} from "react-router-dom";
import {getUser, mySelf} from "Services/user";
import Loading from "../../Utils/Loading";
import useMe from "../../Hooks/useMe";

export default function Profile() {
  const [user, setUser] = useState(null);
  const {id} = useParams();
  const me = useMe();

  useEffect(() => {
    getUser(id).then((json) => {
      setUser(json);
    });
  }, []);

  return  (
    user === null || me === null ? <Loading page/> :
      <SideBar>
        <div className={"flex w-full h-full flex-col items-center justify-start gap-8 md:p-8 p-4 text-gray-700"}>
          <div className={"flex w-full gap-4 justify-between pl-18 md:pl-0"}>
            <Card className={"transi px-5 py-3 px-3flex items-center"}>
              <h1 className={"lg:text-2xl text-xl font-bold text-gray-700"}>Profil de {user.fname} {user.lname}</h1>
            </Card>
          </div>
          <div className={"w-full pb-4 flex flex-col gap-5"}>
            <UserInformation user={user} admin={
              me && me.roles.includes("ROLE_ADMIN")
            } me={me.id === user.id}/>
            <UserFutureLeaves user={user} me={me} />
            {me && me.roles.includes("ROLE_ADMIN") &&
              <UserLeaves user={user}/>
            }
          </div>
        </div>
      </SideBar>
  );
}