import InputText from "./InputText";
import {useEffect, useState} from "react";
import Icon from "./Icon";
import useInputValue from "../Hooks/useInputValue";
function Option(props) {
  const {label, onClick, none} = props;

  if (none) {
    return (
      <li onClick={onClick} className={"px-3 py-2 rounded"}>Aucune option trouvée</li>
    );
  }
  return (
    <li onClick={onClick} className={"px-3 py-2 rounded hover:bg-indigo-100"}>{label}</li>
  );
}

function filterOptions(option, label, selectedValue) {
  if (option.value === selectedValue) {
    return false;
  }
  if (label === "") {
    return true;
  }
  return option.label.toLowerCase().includes(label.toLowerCase());
}
export default function SelectSearch(props) {
  const {id, defaultValue, value, onChange, disabled, clearValue, placeholder} = props;
  const [inputValue, setInputValue] = useInputValue(value || defaultValue || null, onChange, id);
  const [label, setLabel] = useState("");
  const [options, setOptions] = useState(props.options);

  useEffect(() => {
    setOptions(props.options.filter((opt) => filterOptions(opt, label, inputValue)));
    const opt = props.options.find((opt) => opt.value === value);
    if (opt) {
      setLabel(opt.label);
    }
  }, [inputValue, label, props.options]);

  useEffect(() => {
    // find the label in the options and set the value
    const opt = options.find((opt) => opt.value === props.value);
    setInputValue({target: {value: props.value, id: id}});
    if (opt) {
      setLabel(opt.label);
    } else if (value === undefined) {
      setInputValue({target: {value: null, id: id}});
      setLabel("");
    }
  }, [props.value]);

  const changeLabel = (newLabel) => {
    setLabel(newLabel);
    // find the label in the options and set the value
    const opt = options.find((opt) => opt.label === newLabel);

    if (!opt && newLabel === label && inputValue !== null) {
      return;
    }
    if (opt) {
      setInputValue({target: {value: opt.value, id: id}});
      if (clearValue) {
        setLabel("");
        setInputValue({target: {value: null, id: id}});
      }
    } else if (inputValue !== null) {
      setInputValue({target: {value: null, id: id}});
    }
  }

  return (
    <div className={"relative flex flex-col"}>
      <InputText autoComplete="off" className={(props.className || "") + " pr-10 peer"}
                 placeholder={placeholder}
                 onKeyDown={(e) => {
                   if (e.key === "Enter") {
                     e.preventDefault();
                     const opt = options.find((opt) => filterOptions(opt, label, inputValue));
                     if (opt)
                       changeLabel(opt.label);
                   }
                 }}
                 onChange={(e) => changeLabel(e.target.value)}
                 value={label} id={id} disabled={disabled} />
      <div className={"absolute right-3 top-1/2 transform -translate-y-1/2"}>
        <Icon name={"search"} className={"text-gray-600"}/>
      </div>
      {(options.length > 0 || !inputValue) &&
        <ul className={`absolute z-10 top-full left-0 select-none w-full bg-white cursor-pointer hidden peer-focus:block hover:block border border-indigo-300 rounded-b max-h-52 overflow-y-auto`}>
          {options.map((opt, index) => <Option key={index} label={opt.label} onClick={() => {
            changeLabel(opt.label);
          }}/>)}
          {options.length === 0 && <Option none/>}
        </ul>}
    </div>
  );
}