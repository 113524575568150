import {useEffect, useState} from "react";
import Cookies from "js-cookie";
import {mySelf} from "../Services/user";
import {getGroupByManager} from "../Services/group";


export default function useMe() {
  const [me, setMe] = useState(Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null);


  useEffect(() => {
    // if (me && !me.newPwd) {
    //   return;
    // }
    mySelf().then((json) => {
      setMe(json);
      Cookies.set("user", JSON.stringify(json));
    });
  }, []);
  return me;
}

function useMyGroupsManage(me) {
  const [groups, setGroups] = useState(Cookies.get("groups") ? JSON.parse(Cookies.get("groups")) : null);

  useEffect(() => {
    if (groups) {
      return;
    }
    if (me) {
      getGroupByManager(me.id).then((json) => {
        setGroups(json);
        Cookies.set("groups", JSON.stringify(json));
      });
    }
  }, [me]);

  const isManager = (user) => {
    if (!groups || !user || !user.inGroup) {
      return false;
    }
    const result = groups.filter((groups) => groups.id === user.inGroup.id);
    if (result.find((group) => group.super, result)) {
      return 2;
    }
    return result.length > 0;
  }
  return {groupsManage: groups, isManager};
}

export {useMyGroupsManage};