import SideBar from "../../Layouts/SideBar";
import LeavesList from "../../Components/LeavesList/LeavesList";
import {getAccessibleLeaves, leaveResponse} from "../../Services/leave";
import {useEffect, useState} from "react";
import Card from "../../Utils/Card";
import {Link} from "react-router-dom";
import Icon from "../../Utils/Icon";
import useMe from "../../Hooks/useMe";

export default function LeaveResponse() {
  const [pending, setPending] = useState([]);
  const me = useMe();
  function updatePending() {
    leaveResponse(1, {status: 'pending'}).then((json) => {
      let leaves = (json.leaves || []);
      if (!me.roles.includes("ROLE_ADMIN")) {
        leaves = leaves.filter((leave) => {
          if (leave.requester.id === me.id)
            return false;
          return true;
        });
      }
      setPending(leaves);
    });
  }

  useEffect(() => {
    if (!me)
      return;
    updatePending();
  }, [me]);

  return  (
    <SideBar>
      <div className={"flex w-full h-full flex-col items-center justify-start gap-8 md:p-8 p-4 text-gray-700"}>
        <div className={"flex w-full gap-4 justify-between pl-18 md:pl-0"}>
          <Card className={"transi lg:px-5 lg:py-3 px-3 py-2 flex items-center"}>
            <h1 className={"lg:text-2xl text-lg font-bold text-gray-700"}>Validation des Congés</h1>
          </Card>
          <Link to={"/leave/response/history"}>
            <Card className={"transi lg:px-5 lg:py-3 px-3 py-2 flex items-center gap-3 hover:bg-indigo-100"}>
              <Icon name={"history"} className={"text-3xl"}/>
              <h1 className={"text-xl font-medium hidden sm:block"}>Déjà répondus</h1>
            </Card>
          </Link>
        </div>
        <div className={"w-full pb-4 flex flex-col gap-5"}>
          <h2 className={"text-xl font-medium text-gray-900 px-5"}>
            {pending.length} congé(s) en attente
          </h2>
          <LeavesList leaves={pending} config={{toApprove: true, update: updatePending}}/>
        </div>
      </div>
    </SideBar>
  );
}