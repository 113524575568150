import {TextArea} from "Utils/InputText";
import Button, {ConfirmButton} from "Utils/Button";
import {useEffect, useState} from "react";
import SideBar from "Layouts/SideBar";
import Card from "Utils/Card";
import {getLeaveType, getLeaveTypes} from "Services/leaveTypes";
import 'react-date-range/dist/styles.css';
import 'Style/themeDateRange.css';
import {requestLeave} from "Services/leave";
import Toggle from "../../Utils/Toggle";
import Loading from "../../Utils/Loading";
import useIsMobile from "../../Hooks/isMobile";
import Select from "../../Utils/Select";
import Form from "../../Utils/Form";
import DateInput from "../../Utils/DateInput";
import WarningDelay from "../../Components/WarningDelay";
import {getPublicHolidays} from "../../Services/publicHolidays";
import {useSearchParams} from "react-router-dom";
import { getUser, mySelf } from "Services/user";

function getDuration(leave, publicHolidays) {
  let duration = 0;
  let date = new Date(leave.start);
  function sameDay(date1, date2) {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }

  while (date <= leave.end) {
    if (date.getDay() !== 0 && date.getDay() !== 6 && !publicHolidays.find((holiday) => sameDay(date, new Date(holiday.date)))) {
      duration++;
      if (leave.halfStart && sameDay(date, leave.start))
        duration -= 0.5;
      if (leave.halfEnd && sameDay(date, leave.start))
        duration -= 0.5;
    }
    date.setDate(date.getDate() + 1);
  }
  return duration;
}

export default function LeaveRequest() {
  const [duration, setDuration] = useState(0);
  const [leave, setLeave] = useState({"start": new Date(), "end": new Date(), "halfStart": false, "halfEnd": false});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [typeDesc, setTypeDesc] = useState("");
  const [types, setTypes] = useState([]);
  const [publicHolidays, setPublicHolidays] = useState([]);
  const isMobile = useIsMobile();
  const [user, setUser] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = Number(searchParams.get("user")) || undefined;

  const [selectionRange] = useState({
    startDate: leave.start,
    endDate: leave.end,
    key: 'selection',
    color: '#6366f1',
  });

  function updateDuration() {
    setDuration(getDuration(leave, publicHolidays));
  }

  useEffect(() => {
    updateDuration();
    getLeaveTypes().then((json) => {
      json = json.map((type) => {
        return {label: type.name, value: type.id};
      });
      setTypes(json);
    });
    getPublicHolidays().then((json) => {
      setPublicHolidays(json);
    });
    if (userId != undefined)
      getUser(userId).then((json) => {
        setUser(json);
      })
    else
      mySelf().then((json) => {
        setUser(json);
      })
  }, []);

  function handleInputChange(event) {
    const newValue = event.target.value;
    setLeave({...leave, [event.target.id]: newValue});
  }

  useEffect(() => {
    updateDuration();
  }, [leave.start, leave.end, leave.halfStart, leave.halfEnd]);

  useEffect(() => {
    if (leave.type !== undefined && leave.type !== null) {
      getLeaveType(leave.type).then((json) => {
        if (json) {
          setTypeDesc(json.description);
        } else {
          setTypeDesc("");
        }
      });
    }
  }, [leave.type]);

  function handleDateChange(ranges) {
    selectionRange.startDate = ranges.selection.startDate;
    selectionRange.endDate = ranges.selection.endDate;
    setLeave({...leave, start: selectionRange.startDate, end: selectionRange.endDate});
  }

  function confirm() {
    setLoading(true);
    if (userId)
      leave.user = userId;
    requestLeave(leave).then((json) => {
      if (json["error"]) {
        setError("Erreur lors de la demande de congé");
        setLoading(false);
      } else if (json["message"]) {
        setError(json["message"]);
        setLoading(false);
      } else {
        window.history.go(-1);
        setLoading(false);
      }
    });
  }

  const InputLeave = (
    <div className={"flex flex-col"}>
      <label htmlFor="type">Type de congé</label>
      <Select id="type" value={leave.type} options={types} onChange={handleInputChange}/>
      {typeDesc && <p className={"text-sm pt-2 px-2 text-gray-500"}>{typeDesc}</p>}
    </div>
  )

  const Warning = (
    <div className={"flex flex-col gap-4 w-full"}>
      <WarningDelay duration={duration} start={leave.start}/>
      <span className={"text-red-500 text-center"}>Attention, cette action est irréversible</span>
    </div>
  )

  return (
    <SideBar>
      {loading && <Loading page/>}
      <div className={"flex w-full h-full flex-col items-center justify-center gap-5 overflow-y-auto overflow-x-hidden"}>
        <Card className={"transi md:min-w-96 p-10 overflow-y-auto"} shadow>
          <h1 className={"text-2xl font-bold mb-6 md:text-left text-center md:mt-0"}>
            {userId != undefined && user ? "Ajouter un congé pour " + user.fname + " " + user.lname : "Demander un congé"}
          </h1>
          <Form className={"flex flex-col lg:flex-row gap-8"} onSubmit={confirm}>
            <div className={"flex flex-col gap-8"}>
              {isMobile && InputLeave}
              <div className={"flex flex-col gap-1"}>
                <label>
                  {duration} jour(s)<br/>
                  Du {leave.halfStart ? "midi" : "matin"}&nbsp;
                  {new Date(leave.start).toLocaleDateString("fr-FR")}&nbsp;
                  Au {leave.halfEnd ? "midi" : "soir"}&nbsp;
                  {new Date(leave.end).toLocaleDateString("fr-FR")}
                </label>
                <DateInput start={leave.start} end={leave.end}
                           user={user}
                           onChange={handleDateChange}
                           minDate={new Date(new Date().getFullYear() - 1, 0, 1)}
                           maxDate={new Date(new Date().getFullYear() + 1, 11, 31)}
                />
              </div>
            </div>
            <div className={"flex flex-col gap-8"}>
              {!isMobile && InputLeave}
              <div className={"flex flex-col gap-2"}>
                <div className={"flex justify-between pl-1"}>
                  <label htmlFor="halfStart">Départ en fin de matiné</label>
                  <Toggle id="halfStart" defaultToggled={leave.halfStart} onChange={handleInputChange}/>
                </div>
                <div className={"flex justify-between pl-1"}>
                  <label htmlFor="halfEnd">Retour en fin de matiné</label>
                  <Toggle id="halfEnd" defaultToggled={leave.halfEnd} onChange={handleInputChange}/>
                </div>
              </div>
              <div className={"flex flex-col flex-grow"}>
                <label htmlFor="comment">Commentaire</label>
                <TextArea maxLength={255} id="comment" defaultToggled={leave.comment} className={"h-full min-h-10 max-h-52"} onChange={handleInputChange}/>
              </div>
              <div className={"flex flex-col -mt-1"}>
                {error && <p className={"text-red-500 text-center -mt-5 mb-2"}>{error}</p>}
                <div className={"flex w-full gap-4"}>
                  <Button type="button" className={"w-full"} color={"red"}
                          onClick={() => window.history.go(-1)}
                          variant={"outline"}>Annuler</Button>
                  <ConfirmButton className={"w-full"} variant={"solid"}
                                 onClick={confirm}
                                 title={"Êtes-vous sûrs de vouloir demander ce congé ?"}
                                 message={Warning}
                  >Valider</ConfirmButton>
                </div>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    </SideBar>
  );
}