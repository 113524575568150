import {useState} from "react";


export default function useUniqueArray(defaultArray = []) {
  const [array, setArray] = useState(defaultArray);

  function add(item) {
    if (item === "" || item === null || item === undefined) {
      return;
    }
    if (!array.includes(item)) {
      setArray([...array, item]);
    }
  }

  function remove(item) {
    setArray(array.filter((i) => i !== item));
  }

  return [array, add, remove, setArray];
}