import Icon from "./Icon";
import useToggle from "../Hooks/toggle";
import useInputValue from "../Hooks/useInputValue";

function MaskablePasswordInput(props) {
  const {id, defaultValue, value, onChange, disabled, invalid } = props;
  const [inputValue, handleInputChange] = useInputValue(value || defaultValue || '', onChange, id);
  const [showPassword, toggleShowPassword] = useToggle(false)

  return (
    <label className={"flex items-center relative"
      + " rounded border-gray-300 border px-3 py-2 bg-white "
      + (invalid ? " focus-within:ring-2 focus-within:ring-red-500 border-red-500" :
        " focus-within:ring-2 focus-within:ring-indigo-500")
      + (props.className || "")}>
      <input
        type={showPassword ? "text" : "password"}
        id={id || undefined}
        className={"noAuto text-inherit w-full outline-none"}
        value={inputValue}
        onChange={handleInputChange}
        disabled={disabled}/>
      <button type={"button"} onClick={toggleShowPassword} className={"absolute right-2 transi text-gray-600 rounded-full p-1 " +
        "hover:bg-indigo-100 hover:text-indigo-700"}>
        <Icon name={showPassword ? "visibility" : "visibility_off"} className={"aspect-square"}/>
      </button>
    </label>
  );
}

function InputText(props) {
  const {type, id, defaultValue, value, onChange, disabled, invalid, autoComplete, reference, onKeyDown, placeholder, unchangable} = props;
  const [inputValue, handleInputChange] = useInputValue(value || defaultValue || '', onChange, id);

  const handleKeyDown = (e) => {
    if (unchangable) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    handleInputChange(e);
  }

  return (
    <input
      type={type || "text"}
      ref={reference || null}
      id={id || undefined}
      value={inputValue}
      onChange={handleKeyDown}
      disabled={disabled}
      autoComplete={autoComplete || "off"}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      className={"rounded-md border-gray-300 border px-3 py-2 bg-white outline-none "
        + (invalid === false ? " focus:ring-2 focus:ring-red-500 text-red-500 border-red-500" :
          " focus:ring-2 focus:ring-indigo-500 focus:outline-none ")
        + " " + (props.className || "")}
    />
  );
}

function TextArea(props) {
  const {id, defaultValue, value, onChange, disabled, invalid, cols, maxLength } = props;
  const [inputValue, handleInputChange] = useInputValue(value || defaultValue || '', onChange, id);

  return (
    <textarea
      cols={cols || 30}
      maxLength={maxLength || null}
      id={id || undefined}
      value={inputValue}
      onChange={handleInputChange}
      disabled={disabled}
      className={"rounded-md border-gray-300 border px-3 py-2 bg-white outline-none "
        + (invalid === false ? " focus:ring-2 focus:ring-red-500 text-red-500 border-red-500" :
          " focus:ring-2 focus:ring-indigo-500 focus:outline-none ")
        + " " + (props.className || "")}
    />
  );
}

export default InputText;
export {MaskablePasswordInput, TextArea};