import Icon from "../../Utils/Icon";
import Card from "../../Utils/Card";
import LeaveRow from "./LeaveRow";
import {useEffect, useState} from "react";
import {getUsersManageInfo} from "../../Services/user";

export default function LeavesList(props) {
  const {config, leaves} = props;
  const [usersManage, setUsersManage] = useState(null);

  useEffect(() => {
    const users = [];
    if (!leaves)
      return;
    leaves.forEach((leave) => {
      if (leave.requester) {
        users.push(leave.requester.id);
      }
    });
    getUsersManageInfo(users).then((json) => {
      setUsersManage(json);
    });
  }, [leaves]);


  if (!leaves || leaves.length === 0) {
    return (
      <Card className={"flex flex-col items-center justify-center p-4"}>
        <Icon name={"inbox"} className={"text-3xl text-gray-400"}/>
        <p className={"text-gray-400"}>Aucune demande</p>
      </Card>
    );
  }

  return (
    <>
      {(config.history || config.auto) && config.hide !== true && (
        <div className={"flex w-full px-5 gap-x-10 gap-y-1 flex-wrap"}>
          {!config.history && (
            <div className={"flex gap-2 items-center w-32"}>
              <div className={"bg-indigo-500 w-8 h-6 rounded border border-gray-300"}/>
              <span className={"text-gray-700"}>En attente</span>
            </div>
          )}
          <div className={"flex gap-2 items-center w-32"}>
            <div className={"bg-green-500 w-8 h-6 rounded border border-gray-300"}/>
            <span className={"text-gray-700"}>Accepté</span>
          </div>
          <div className={"flex gap-2 items-center w-32"}>
            <div className={"bg-red-500 w-8 h-6 rounded border border-gray-300"}/>
            <span className={"text-gray-700"}>Refusé</span>
          </div>
          <div className={"flex gap-2 items-center w-32"}>
            <div className={"bg-orange-500 w-8 h-6 rounded border border-gray-300"}/>
            <span className={"text-gray-700"}>Ignoré</span>
          </div>
          <div className={"flex gap-2 items-center w-32"}>
            <div className={"bg-gray-500 w-8 h-6 rounded border border-gray-300"}/>
            <span className={"text-gray-700"}>Annulé</span>
          </div>
        </div>
      )}
      {leaves && usersManage && leaves.map((leave, index) => (
        <LeaveRow key={index} leave={leave} config={config} manager={usersManage}/>
      ))
      }
    </>
  )

}