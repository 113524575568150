import useToggle from "../Hooks/toggle";
import {useEffect} from "react";

export default function Toggle(props) {
  const { defaultToggled, onChange, id, disabled } = props;
  const [isToggled, toggle] = useToggle(defaultToggled || false);

  useEffect(() => {
    if (defaultToggled != isToggled)
      toggle();
  }, [defaultToggled]);

  function handleChange() {
    if (disabled) {
      return;
    }
    onChange({target: {id: id, value: !isToggled}});
    toggle();
  }

  const position = isToggled ? "translate-x-full" : "";
  const bg = isToggled ? "bg-indigo-500" : "bg-gray-400";

  return (
    <label className={disabled ? "cursor-not-allowed" : "cursor-pointer"}
           style={{filter: disabled ? "opacity(0.4)" : ""}}>
      <div className={bg + " w-10 h-6 transi rounded-full p-1 flex items-center relative"}>
        <div className={"bg-white w-4 h-4 rounded-full transi absolute left-1 " + position}></div>
      </div>
      <input id={id} disabled={disabled} type="checkbox" checked={isToggled} onChange={handleChange} className={"hidden"}/>
    </label>
  );
}