import {Link, useParams} from "react-router-dom";
import SideBar from "../../Layouts/SideBar";
import {useEffect, useState} from "react";
import {getGroup} from "../../Services/group";
import Card from "../../Utils/Card";
import LeavePreview from "../../Components/LeavePreview/LeavePreview";
import {getUsersByGroup} from "../../Services/user";
import GroupLeaves from "../../Components/Group/Leaves";


export default function Group() {
  const {id} = useParams();
  const [group, setGroup] = useState(null);
  const [users, setUsers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [search] = useState(
    {
      split: false,
      withManager: true,
      withOutMe: false,
      user: null,
      group: id
    });

  useEffect(() => {
    getGroup(id).then((json) => {
      if (json) {
        setGroup(json);
        setManagers(json.managers)
      }
    });
    getUsersByGroup(id, true, true).then((json) => {
      if (json) {
        setUsers(json);
      }
    });
  }, []);

  return (
    <SideBar>
      <div className={"flex w-full h-full flex-col items-center justify-start gap-8 md:p-8 p-4 text-gray-700"}>
        <div className={"flex w-full gap-4 justify-between pl-18 md:pl-0"}>
          <Card className={"transi px-5 py-3 px-3flex items-center"}>
            <h1 className={"lg:text-2xl text-xl font-bold text-gray-700"}>Service {group?.name}</h1>
          </Card>
        </div>
        <Card className={"flex flex-col items-center justify-center p-4 w-full"}>
          <h1 className={"text-2xl w-full font-bold text-gray-700 pt-2 pl-2"}>Calendrier des congés</h1>
          <div className={"w-full"}>
            <LeavePreview search={search} users={users}/>
          </div>
        </Card>
        <Card className={"p-4 w-full"}>
          <h1 className={"text-2xl w-full font-bold text-gray-700 pt-2 pl-2"}>Les managers</h1>
          <div className={"flex flex-wrap gap-4 mt-4"}>
            {managers.map((manager, key) => (
              <Link key={key} to={`/user/${manager.user.id}`}>
                <Card className={"flex flex-col items-center justify-center p-4 sm:min-w-52"}>
                  <h1 className={"text-xl font-bold"}>{manager.user.fname} {manager.user.lname}</h1>
                  <p className={"text-gray-500"}>{manager.user.email}</p>
                </Card>
              </Link>
            ))}
          </div>
        </Card>
        <Card className={"p-4 w-full"}>
          <h1 className={"text-2xl font-bold text-gray-700"}>Membres du service</h1>
          <div className={"flex flex-wrap gap-4 mt-4"}>
            {users.map((user, key) => {
                if (user.inGroup?.id !== group?.id)
                  return null;
                return <Link key={key} to={`/user/${user.id}`}>
                  <Card className={"flex flex-col items-center justify-center p-4 sm:min-w-52 "}>
                    <h1 className={"text-xl font-bold"}>{user.fname} {user.lname}</h1>
                    <p className={"text-gray-500"}>{user.email}</p>
                  </Card>
                </Link>
              }
            )}
          </div>
        </Card>
        <div className={"w-full pb-4 flex flex-col gap-5"}>
          <GroupLeaves group={group}/>
        </div>
      </div>
    </SideBar>
  )
}