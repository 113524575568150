import {getToken} from "../Hooks/useToken";

async function mySelf(token = null) {
  const url = process.env.REACT_APP_API + 'user';
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + (token || getToken())
    }
  })

  if (resp.status === 200) {
    return resp.json()
  }
  return null
}

async function getUsers(inactive = false) {
  const url = process.env.REACT_APP_API + 'admin/users?inactive=' + inactive;
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })

  if (resp.status === 200) {
    return resp.json()
  }
  return null
}

async function createUser(data) {
  const url = process.env.REACT_APP_API + 'admin/user';
  data.roles = [data.roles] || "ROLE_USER";
  data.group = data.group || null;
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(data)
  })

  if (!resp.ok) {
    return null;
  }

  return resp.json()
}

async function deleteUser(id) {
  const url = process.env.REACT_APP_API + `admin/user?id=${id}`;
  const resp = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
  })

  return resp.status === 204;
}

async function getUser(id) {
  const url = process.env.REACT_APP_API + `user?id=${id}`;
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
  })

  if (resp.status === 200) {
    return resp.json();
  }
  return null;
}

async function updateUser(data) {
  const url = process.env.REACT_APP_API + 'admin/user';
  data.roles = [data.roles] || "ROLE_USER";
  data.group = data.group || null;
  const resp = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(data)
  })
  if (!resp.ok) {
    return null;
  }

  return resp.json()
}

async function getUsersByGroup(group, withManager = true, withAccess = false) {
  const url = process.env.REACT_APP_API + `users/group/${group}?managers=${withManager}&access=${withAccess}`;
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": "Bearer " + getToken(),
    },
  });

  if (resp.status === 200) {
    return resp.json();
  }
  return null;
}

async function newPassword(password, token = null) {
  const url = process.env.REACT_APP_API + 'new-password';
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + (token || getToken()),
    },
    body: JSON.stringify({
      "password": password,
    })
  })

  return resp.status === 200;
}

async function forgotPassword(email) {
  const url = process.env.REACT_APP_API + "login/forgot-password";
  const resp = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({email: email}),
  });

  return resp.status === 200;
}

async function getUsersManageInfo(ids) {
  const url = process.env.REACT_APP_API + `user/manage/info?idList=${ids.join(",")}`;
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": "Bearer " + getToken(),
    },
  });

  if (resp.status === 200) {
    return resp.json();
  }
  return null;
}

export {
  mySelf,
  getUsers,
  createUser,
  deleteUser,
  getUser,
  updateUser,
  getUsersByGroup,
  newPassword,
  forgotPassword,
  getUsersManageInfo
};