import {useEffect, useState} from "react";
import useFirstAndLastDay from "./useFirstAndLastDay";


export default function useMonth(day) {
  const [days, setDays] = useState(null);
  const [firstDay, lastDay] = useFirstAndLastDay(day);
  useEffect(() => {
    const month = [];

    if (!firstDay || !lastDay)
      return;

    for (let i = new Date(firstDay); i <= lastDay; i.setDate(i.getDate() + 1)) {
      const day = new Date(i);
      month.push(day);
    }
    setDays(month);
  }, [day, firstDay, lastDay]);

  return days;
}