import InputText from "../../Utils/InputText";
import Button from "../../Utils/Button";
import {useEffect, useState} from "react";
import Loading from "../../Utils/Loading";
import SideBar from "../../Layouts/SideBar";
import Card from "../../Utils/Card";
import {Link, useParams} from "react-router-dom";
import {
  createPublicHoliday,
  getPublicHoliday,
  getPublicHolidays,
  updatePublicHoliday
} from "../../Services/publicHolidays";
import useAutoChange from "../../Hooks/useAutoChange";
import {Calendar} from "react-date-range";
import Toggle from "../../Utils/Toggle";
import 'Style/themeDateRange.css';
import { fr } from 'date-fns/locale'
import Form from "../../Utils/Form";
export default function CreatePublicHolidays() {
  const [holiday, setHoliday] = useState({name: "", date: new Date(), repeat: false});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const {id} = useParams();
  const handleInputChange = useAutoChange(holiday, setHoliday);
  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    if (id) {
      getPublicHoliday(id).then((json) => {
        setHoliday(json);
      });
    }
    getPublicHolidays().then((json) => {
      json = json.map((holiday) => {
        return {...holiday, date: new Date(holiday.date)};
      });
      setHolidays(json);
    });
  }, []);


  async function confirm() {
    setLoading(true);
    let json;
    if (id) {
      json = await updatePublicHoliday(holiday);
    } else
      json = await createPublicHoliday(holiday);
    if (json && json.message === "ok") {
      window.location.href = "/holiday/list";
    } else {
      setLoading(false);
      setError(json.message || "Il y a eu une erreur lors de la validation.");
    }
  }

  return (
    <SideBar>
      {loading ? <Loading page transparent/> : null}
      <div className={"flex w-full h-full flex-col items-center justify-center gap-5"}>
        <Card className={"transi md:min-w-96 p-10"} shadow>
          <h1 className={"text-2xl font-bold mb-6"}>{id ? "Modifier" : "Ajouter"} un jour férié ou non travaillé</h1>
          <Form className={"flex flex-col gap-6"} onSubmit={confirm}>
            <div className={"flex flex-col"}>
              <label htmlFor="lname">Nom du Jour férié ou non travaillé</label>
              <InputText type="text" id="name" value={holiday.name} onChange={handleInputChange}/>
            </div>
            <div className={"flex flex-col"}>
              <label htmlFor="date">Date</label>
              <Calendar
                date={holiday.date}
                locale={fr}
                minDate={new Date(new Date().getFullYear(), 0, 1)}
                maxDate={new Date(new Date().getFullYear() + 1, 11, 31)}
                disabledDay={(date) => {
                  for (const holiday of holidays) {
                    if (holiday.id === Number(id))
                      continue;
                    if (holiday.date.getDate() === date.getDate() && holiday.date.getMonth() === date.getMonth()) {
                      if (holiday.repeat)
                        return true;
                      if (holiday.date.getFullYear() === date.getFullYear())
                        return true;
                    }
                  }
                  return false;
                }}
                color={'#6366f1'}
                onChange={(date) => {
                  setHoliday({...holiday, date: date});
                }}
              />
            </div>
            <div className={"flex justify-between"}>
              <label htmlFor="repeat">Répéter chaque année à la même date</label>
              <Toggle id={"repeat"} defaultToggled={holiday.repeat} onChange={handleInputChange}/>
            </div>
            <div className={"flex flex-col"}>
              {error && <p className={"text-red-500 text-center mb-2"}>{error}</p>}
              <Button type="submit" variant={"solid"}>{id ? "Modifier" : "Ajouter"}</Button>
            </div>
          </Form>
        </Card>
        <Link to={"/holiday/list"} className={"underline text-indigo-500"}>Retourner à la liste des jours fériés</Link>
      </div>
    </SideBar>
  );
}