import { BeatLoader } from "react-spinners";

export default function Loading(props) {
  const {page, white} = props;
  const Loader = () => <BeatLoader color={!white ? '#6366f1' : "#fff"} />

  if (page !== true)
    return Loader
  const opacity = props.transparent === true ? "bg-opacity-50" : "";

  return (
    <div className={"fixed z-10 top-0 left-0 w-screen h-screen bg-gray-100 flex items-center justify-center " + opacity}>
      <Loader/>
    </div>
  );
}