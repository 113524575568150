import InputText, {MaskablePasswordInput} from "../Utils/InputText";
import Button from "../Utils/Button";
import {login} from "../Services/login";
import {useState} from "react";
import Loading from "../Utils/Loading";
import logo from "../Assets/logo.png";
import useToken from "../Hooks/useToken";
import Form from "../Utils/Form";
import Modal from "../Utils/Modal";
import Card from "../Utils/Card";
import {forgotPassword} from "../Services/user";
import Cookies from "js-cookie";
export default function Login(props) {
  const [user, setUser] = useState({email: "", password: ""});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const {setToken} = useToken();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setUser({...user, [event.target.id]: newValue});
  }

  const handleSubmit = async () => {
    setLoading(true);
    const json = await login(user.email, user.password);
    if (json) {
      const cookies = Cookies.get();
      for (const cookie in cookies)
        Cookies.remove(cookie);
      setToken(json.token);
      if (props.reload) {
        window.location.reload();
      } else
        window.location.href = "/profile";
      setError("");
    } else {
      setLoading(false);
      setError("Email ou mot de passe incorrect");
    }
  }

  const sendResetPassword = async () => {
    forgotPassword(email).then((resp) => {
      if (resp) {
        setShow(false);
      }
    });
  }

  return (
    <div className={"flex w-screen min-h-screen flex-col items-center justify-center gap-8"}>
      {loading ? <Loading page/> : null}
      <div className={"flex flex-col items-center gap-4"}>
        <img src={logo} className={"w-24 h-24"} alt={"logo"}/>
        <div>
          <h1 className={"w-full text-center text-4xl font-bold text-indigo-600"}>Zenitime</h1>
          <h2 className={"text-center text-2xl font-semibold text-indigo-400"}>{ process.env.REACT_APP_COMPANY_NAME }</h2>
        </div>
      </div>
      <Form onSubmit={handleSubmit}
            className={"flex flex-col p-10 gap-8 md:min-w-96 rounded-xl border bg-gray-50 border-indigo-300 shadow-4xl shadow-indigo-200"}>
        <h2 className={"text-2xl font-bold"}>Connexion</h2>
        <div className={"flex flex-col"}>
          <label htmlFor="email">Email</label>
          <InputText type="email" id="email" autoComplete="on" placeholder={"example@email.com"} value={user.email} onChange={handleInputChange}/>
        </div>
        <div>
          <label htmlFor="password">Mot de passe</label>
          <MaskablePasswordInput type="password" id="password" value={user.password} onChange={handleInputChange}/>
        </div>
        <div className={"flex flex-col"}>
          <p className={"text-red-500 text-center p-1"}>{error}</p>
          <Button type="submit" variant={"solid"}>Se connecter</Button>
          <p className={"text-indigo-500 text-center underline cursor-pointer text-sm mt-1"} onClick={() => setShow(true)}>Mot de passe oublié ?</p>
        </div>
      </Form>
      <div className={"h-32"}></div>
      <Modal show={show} setShow={setShow}>
        <Card className={"p-8 flex flex-col gap-4"}>
          <h1 className={"text-2xl font-bold"}>Mot de passe oublié</h1>
          <div>
          <p className={"text-gray-500"}>Entrez votre email pour recevoir un lien de réinitialisation</p>
          <p className={"text-gray-500"}>Le lien sera valide pendant 1h</p>
          </div>
          <div className={"flex flex-col"}>
            <label htmlFor="forgot" className={"text-gray-600 ml-2"}>Email</label>
            <InputText type="email" id="forgot" value={user.email} onChange={(e) => setEmail(e.target.value)} placeholder={"Email"}/>
          </div>
          <div className={"flex gap-4 justify-end"}>
            <Button variant={"ghost"} onClick={() => setShow(false)}>Annuler</Button>
            <Button variant={"solid"} onClick={sendResetPassword}
            >Envoyer</Button>
          </div>
        </Card>
      </Modal>
    </div>
  );
}