import useMonth from "../../Hooks/useMonth";
import LeaveCell from "./Cell";

export default function DaysInMonth(props) {
  const {setHover, hover, month, holidays} = props;
  const days = useMonth(month);

  function isToday(date) {
    const today = new Date();
    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth();
  }

  function inTwoNumber(number) {
    return number < 10 ? "0" + number : number;
  }

  if (!days)
    return null;

  return (
    <thead>
    <tr className={"hover:bg-indigo-100"}>
      <th className={"hover:bg-indigo-200 " + (hover === -1 ? "bg-indigo-100" : "")}
          onMouseEnter={() => setHover(-1)}
          onMouseLeave={() => setHover(null)}
      >
        <p className={"text-sm text-gray-600  py-0.5 px-0.5 md:py-1 md:px-2"}>{
          days[15].toLocaleDateString("fr-FR", {month: "long"}).toUpperCase()
        }</p>
      </th>
      {days.map((day, key) => {
        return (
          <LeaveCell day={day} key={key} id={key} hover={hover} setHover={setHover} holidays={holidays}
                     className={"p-0.5 md:p-1 text-gray-500"}>
            <p className={"text-sm text-center peer/day " + (isToday(day) ? "text-indigo-500 font-semibold underline" : "")}>{
              inTwoNumber(day.getDate())
            }</p>
          </LeaveCell>
        );
      })}
    </tr>
    </thead>
  );
}