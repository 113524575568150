import {getToken} from "../Hooks/useToken";

async function getGroups() {
  const url = process.env.REACT_APP_API + 'admin/groups';
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })

  if (resp.status === 200) {
    return resp.json()
  }
  return null
}

async function getGroup(id, accesses= false) {
  const url = process.env.REACT_APP_API + `group?id=${id}&accesses=${accesses}`;
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })

  if (resp.status === 200) {
    return resp.json()
  }
  return null
}

async function createGroup(data) {
  const url = process.env.REACT_APP_API + 'admin/group';
  const {name, manager, accesses, supers} = data;
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify({
      "name": name,
      "managers": manager ? [manager] : [],
      "accesses": accesses || [],
      "supers": supers || []
    })
  })

  return resp.json()
}

async function updateGroup(data) {
  const url = process.env.REACT_APP_API + 'admin/group';
  const {id, name, managers, accesses, supers} = data;
  const resp = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify({
      "id": id,
      "name": name,
      "managers": managers || [],
      "accesses": accesses || [],
      "supers": supers || []
    })
  })

  return resp.json();
}

async function deleteGroup(id) {
  const url = process.env.REACT_APP_API + `admin/group?id=${id}`;
  const resp = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })

  return resp.status === 204;
}

async function getMyGroups() {
  const url = process.env.REACT_APP_API + 'my-groups';
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })

  if (resp.status === 200) {
    return resp.json();
  }
  return null;
}

async function getGroupByManager(user) {
  const url = process.env.REACT_APP_API + `group-by-manager/${user}`;
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })

  if (resp.status === 200) {
    return resp.json();
  }
  return null;
}

export {getGroups, getGroup, createGroup, updateGroup, deleteGroup, getMyGroups, getGroupByManager};