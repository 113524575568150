import {useEffect, useState} from "react";


export default function useFirstAndLastDay(dayInMonth) {
  const [firstDay, setFirstDay] = useState(null);
  const [lastDay, setLastDay] = useState(null);

  useEffect(() => {
    if (!dayInMonth)
      return;
    const first = new Date(dayInMonth.getFullYear(), dayInMonth.getMonth(), 1);
    const last = new Date(dayInMonth.getFullYear(), dayInMonth.getMonth() + 1, 0);
    while (first.getDay() !== 1) {
      first.setDate(first.getDate() - 1);
    }
    while (last.getDay() !== 0) {
      last.setDate(last.getDate() + 1);
    }
    setFirstDay(first);
    setLastDay(last);
  }, [dayInMonth]);

  return [firstDay, lastDay];
}