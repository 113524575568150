import SideBar from "../../Layouts/SideBar";
import LeavesList from "../../Components/LeavesList/LeavesList";
import {historyResponse} from "../../Services/leave";
import {useEffect, useState} from "react";
import Card from "../../Utils/Card";
import {Link, useSearchParams} from "react-router-dom";
import Icon from "../../Utils/Icon";
import Pagination from "../../Utils/Pagination";

export default function HistoryResponse() {
  const [leaves, setLeaves] = useState({"leaves": [], "total": 0});
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get("page")) || 1;

  function update() {
    historyResponse(page, {mine: true}).then((json) => {
      setLeaves(json || []);
    });
  }

  useEffect(() => {
    update();
  }, [page]);

  return  (
    <SideBar>
      <div className={"flex w-full h-full flex-col items-center justify-start gap-8 md:p-8 p-4 text-gray-700"}>
        <div className={"flex w-full gap-4 justify-between pl-18 md:pl-0"}>
          <Card className={"transi lg:px-5 lg:py-3 px-3 py-2 flex items-center"}>
            <h1 className={"lg:text-2xl text-lg font-bold text-gray-700"}>Congés déjà Répondus</h1>
          </Card>
          <Link to={"/leave/response/pending"}>
            <Card className={"transi lg:px-5 lg:py-3 px-3 py-2 flex items-center gap-3 hover:bg-indigo-100"}>
              <Icon name={"update"} className={"text-3xl"}/>
              <h1 className={"text-xl font-medium hidden sm:block"}>En attente</h1>
            </Card>
          </Link>
        </div>
        <div className={"w-full pb-4 flex flex-col gap-5"}>
          <h2 className={"text-xl font-medium text-gray-900 px-5"}>
            {leaves.total} congé(s) Répondu(s)
          </h2>
          <LeavesList leaves={leaves["leaves"]} config={{history: true, update: update}}/>
          <Pagination currentPage={page} totalPage={Math.ceil(leaves.total / 10)} setPage={(page) => setSearchParams({page})}/>
        </div>
      </div>
    </SideBar>
  );
}