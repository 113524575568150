import {DateRange} from "react-date-range";
import {fr} from "date-fns/locale";
import {useEffect, useState} from "react";
import {getAccessibleLeaves} from "../Services/leave";
import {getPublicHolidays} from "../Services/publicHolidays";


export default function DateInput(props) {
  const {onChange} = props;
  const [holidays, setHolidays] = useState([]);
  const [myLeaves, setMyLeaves] = useState([]);
  const [selectionRange] = useState({
    startDate: props.start,
    endDate: props.end,
    key: 'selection',
    color: '#6366f1',
  });

  function handleDateChange(ranges) {
    selectionRange.startDate = ranges.selection.startDate;
    selectionRange.endDate = ranges.selection.endDate;
    onChange(ranges);
  }

  useEffect(() => {
    const today = new Date(new Date().getFullYear() - 1, 0, 1);
    const lastDay = new Date(today.getFullYear() + 1, 11, 0);
    const search = {user: props.user?.id, withManager: true};
    if (!props.user)
      return;
    getAccessibleLeaves({
      ...search, start: today, end: lastDay, split: false}).then((json) => {
      if (json) {
        json = json.filter((leave) => {
          return leave.status === "approved" || leave.status === "pending";
        });
        json = json.map((leave) => {
          return {
            startDate: new Date(leave.start),
            endDate: new Date(leave.end),
            key: 'leave' + leave.id,
            color: leave.type.color + '80',
            showDateDisplay: false,
            autoFocus: false,
            disabled: true
          };
        });
        setMyLeaves(json);
      }
    });
    getPublicHolidays().then((json) => {
      json = json.map((holiday) => {
        return {...holiday, date: new Date(holiday.date)};
      });
      setHolidays(json);
    });
  }, [props.user]);

  return (
    <DateRange ranges={[selectionRange, ...myLeaves]}
               showSelectionPreview={true}
               onChange={handleDateChange}
               minDate={new Date(new Date().getFullYear() - 1, 0, 1)}
               maxDate={new Date(new Date().getFullYear() + 1, 11, 31)}
               disabledDay={(date) => {
                 if (date.getDay() === 0 || date.getDay() === 6)
                   return true;
                 for (const holiday of holidays) {
                   if (holiday.date.getDate() === date.getDate() && holiday.date.getMonth() === date.getMonth()) {
                     if (holiday.repeat)
                       return true;
                     if (holiday.date.getFullYear() === date.getFullYear())
                       return true;
                   }
                 }
                 return false;
               }}
               locale={fr}
    />)
}