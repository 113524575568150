import {useEffect, useState} from "react";
import {getAccessibleLeaves} from "../../Services/leave";
import DaysInMonth from "./Days";
import UserRow from "./UserRow";
import useFirstAndLastDay from "../../Hooks/useFirstAndLastDay";
import Icon from "../../Utils/Icon";
import Button from "../../Utils/Button";
import {getHolidaysBetween} from "../../Services/publicHolidays";
import {Link} from "react-router-dom";

export default function LeavePreview(props) {
  const {search, users, group} = props;
  const [leavesUsers, setLeaveUsers] = useState([]);
  const [leaves, setLeaves] = useState(null);
  const [date, setDate] = useState(new Date());
  const [columnHovered, setColumnHovered] = useState(null);
  const [month, setMonth] = useState(null);
  const [firstDay, lastDay] = useFirstAndLastDay(month);
  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    if (!search || !month || !firstDay || !lastDay)
      return;
    getHolidaysBetween(firstDay, lastDay).then((json) => {
      if (!json)
        return;
      json = json.map((holiday) => {
        return {...holiday, date: new Date(holiday.date)};
      });
      setHolidays(json);
    });

    getAccessibleLeaves({
      ...search,
      start: firstDay,
      end: lastDay
    }).then((json) => {
      if (json) {
        json = json.filter((leave) => {
          return leave.status === "approved" || leave.status === "pending";
        });
        setLeaves(json);
      }
    });
  }, [search, firstDay, lastDay]);

  useEffect(() => {
    setDate(props.date || new Date());
  }, [props.date]);

  useEffect(() => {
    setMonth(new Date(date.getFullYear(), date.getMonth(), 15));
  }, [date]);

  useEffect(() => {
    if (!leaves)
      return;
    const newLeavesUsers = [];
    leaves.forEach((leave) => {
      if (!leave.requester)
        return;
      const key = leave.requester.fname + " " + leave.requester.lname;
      if (!newLeavesUsers[key])
        newLeavesUsers[key] = [];
      newLeavesUsers[key].push(leave);
    });
    setLeaveUsers(newLeavesUsers);
  }, [leaves]);

  if (!leaves)
    return null;
  let lastGroup = undefined;
  return (
    <>
      <div className={"flex justify-around p-2"}>
        <Button icon variant={"ghost"} onClick={() => setDate(new Date(date.getFullYear(), date.getMonth() - 1, 15))}>
          <Icon name={"chevron_left"}/>
        </Button>
        <h2 className={"text-lg font-semibold"}>{date.toLocaleDateString("fr-FR", {month: "long", year: "numeric"}).toUpperCase()}</h2>
        <Button icon variant={"ghost"} onClick={() => setDate(new Date(date.getFullYear(), date.getMonth() + 1, 15))}>
          <Icon name={"chevron_right"}/>
        </Button>
      </div>
      <div className={"overflow-x-auto"}>
        <table className={"w-full overflow-x-auto overflow-y-visible text-left"} cellSpacing={0} cellPadding={0}>
          <DaysInMonth month={month} setHover={setColumnHovered} hover={columnHovered} holidays={holidays}/>
          <tbody>
          {users.map((user, key) => {
            const userName = user.fname + " " + user.lname;

            if (lastGroup !== null && lastGroup !== user.inGroup?.id && group) {
              lastGroup = user.inGroup?.id;
              return (
                <>
                  <tr className={""} key={userName + key}>
                    <td colSpan={1} className={"text-left font-semibold text-sm pl-5 pt-1 bg-indigo-100 hover:bg-indigo-200"}>
                      {user.inGroup ?
                      <Link key={key} to={`/group/${user.inGroup.id}`} className={"hover:underline"}>
                        {user.inGroup.name}
                      </Link> :
                      "Sans Service"}
                    </td>
                  </tr>
                  <UserRow key={key} user={user} leaves={leavesUsers[userName] ? leavesUsers[userName] : []} month={month}
                           setHover={setColumnHovered} hover={columnHovered} holidays={holidays}/>
                </>
              )
            }
            lastGroup = user.inGroup?.id;
            return (
              <UserRow key={key} user={user} leaves={leavesUsers[userName] ? leavesUsers[userName] : []}
                       month={month}
                       setHover={setColumnHovered} hover={columnHovered} holidays={holidays}/>
            )
          })}
          </tbody>
        </table>
      </div>
    </>
  )
}