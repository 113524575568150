import {useEffect, useRef, useState} from "react";
import {getMyGroups} from "../../Services/group";
import Card from "../../Utils/Card";
import SideBar from "../../Layouts/SideBar";
import Loading from "../../Utils/Loading";
import {Link} from "react-router-dom";
import Icon from "../../Utils/Icon";
import useMe, {useMyGroupsManage} from "../../Hooks/useMe";
import LeavePreview from "../../Components/LeavePreview/LeavePreview";
import {getUsers, getUsersByGroup} from "../../Services/user";
import Button from "../../Utils/Button";
import {useWidth} from "../../Hooks/isMobile";


export default function GroupSelect() {
  const [groups, setGroups] = useState([]);
  const user = useMe();
  const {groupsManage} = useMyGroupsManage(user);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [showLeave, setShowLeave] = useState(false);
  const contentRef = useRef();
  const winWidth = useWidth();

  useEffect(() => {
    getMyGroups().then((json) => {
      if (json) {
        setGroups(json);
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    const groupsId = groups.map((group) => group.id).join("-");
    if (!groupsId)
      return;
    if (user.roles.includes("ROLE_ADMIN")) {
      getUsers().then((json) => {
        if (json) {
          json.sort((a, b) => {
            if (!a.inGroup)
              return 10;
            if (!b.inGroup)
              return -10
            return a.inGroup.id - b.inGroup.id;
          });
          setUsers(json);
        }
      });
      return;
    }
    getUsersByGroup(groupsId, true).then((json) => {
      if (json) {
        setUsers(json);
      }
    });
  }, [groups]);


  useEffect(() => {
    if (!contentRef.current)
      return;
    if (showLeave) {
      const contentHeight = contentRef.current.scrollHeight;
      contentRef.current.style.height = contentHeight + "px";
    } else {
      contentRef.current.style.height = "0";
    }
  }, [showLeave]);

  if (loading) {
    return <Loading page/>
  }

  if (groups.length === 0) {
    return (
      <SideBar>
        <div className={"flex flex-col items-center justify-center h-full"}>
          <h1 className={"text-2xl font-bold text-gray-700"}>Vous n'avez pas de service</h1>
          <p className={"text-gray-500"}>Veuillez contacter un administrateur</p>
        </div>
      </SideBar>
    )
  }

  return (
    <SideBar>
      <div className={"flex w-full h-full flex-col items-center justify-start gap-8 md:p-8 p-4 text-gray-700"}>
        <div className={"flex w-full gap-4 justify-between pl-18 md:pl-0"}>
          <Card className={"transi px-5 py-3 px-3flex items-center"}>
            <h1 className={"lg:text-2xl text-xl font-bold text-gray-700"}>Mes Services</h1>
          </Card>
          {((groupsManage && groupsManage.length > 0) || user.roles.includes("ROLE_ADMIN")) &&
          <Link to={"/leave/filters"}>
            <Card className={"transi lg:px-5 lg:py-3 px-3 py-2 flex items-center gap-2 hover:bg-indigo-100"}>
              <Icon name={"beach_access"} className={"text-3xl"}/>
              <h1 className={"text-xl font-medium hidden sm:block"}>Liste des congés</h1>
            </Card>
          </Link>}
        </div>
        <Card className={"w-full p-6 transi"}>
          <Button onClick={() => setShowLeave(!showLeave)} variant={"outline"}>Voir les congés</Button>
          <div className={"transi overflow-y-hidden"} style={{height: 0}}
               ref={contentRef}>
            <LeavePreview search={{split: false, withManager: true, withOutMe: false, user: null, group: null}} users={users} group/>
          </div>
        </Card>
        <div className={"flex flex-wrap gap-4 w-full"}>
          {groups.map((group, key) => (
            <button key={key} className={"w-full"} style={winWidth < 768 ? {} : winWidth < 1024 ? {width: "calc(50% - 0.5rem)"} : {width: "calc(33.33% - 0.666rem)"}}
                  onClick={() => window.location.href = `/group/${group.id}`}>
            <Card className={"flex flex-col items-center justify-center px-4 py-6 cursor-pointer h-full"}>
              <h1 className={"text-xl font-bold"}>Service {group.name}</h1>
              <p className={"text-gray-500"}>{user.inGroup?.id === group.id ? "Vous êtes dans ce service" : "Cliquez pour voir les congés"}</p>
              <b>
                {group.managers.find((manager) => manager.user.id === user.id) ? "Vous êtes Manager" : ""}
              </b>
            </Card>
            </button>
          ))}
        </div>
      </div>
    </SideBar>
  )
}