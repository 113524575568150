import {useEffect, useState} from "react";


export default function useInputValue(value, onChange, id = null) {
  const [inputValue, setInputValue] = useState(value);

  function handleInputChange(event)  {
    const newValue = event.target.value;
    setInputValue(newValue);
    if (onChange) {
      onChange(event);
    }
  }

  useEffect(() => {
    handleInputChange({target: {value: value, id: id}});
  }, [value, id]);
  return [inputValue, handleInputChange];
}