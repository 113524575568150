import {getToken} from "../Hooks/useToken";
async function getLeaveTypes() {
  const url = process.env.REACT_APP_API + `leave/types`;
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": "Bearer " + getToken(),
      "Content-Type": "application/json"
    }
  });

  if (resp.status === 200) {
    return resp.json();
  }
  return null;
}

async function getLeaveType(id) {
  const url = process.env.REACT_APP_API + `leave/type?id=${id}`;
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": "Bearer " + getToken(),
      "Content-Type": "application/json"
    }
  });

  if (resp.status === 200) {
    return resp.json();
  }
  return null;
}

async function createLeaveType(data) {
  const url = process.env.REACT_APP_API + 'admin/leave/type';
  const {name, description, color, payKey} = data;
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify({
      "name": name,
      "desc": description,
      "color": color,
      "payKey": payKey
    })
  })

  return resp.json()
}

async function updateLeaveType(data) {
  const url = process.env.REACT_APP_API + 'admin/leave/type';
  const {id, name, description, color, payKey} = data;
  const resp = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify({
      "id": id,
      "name": name,
      "desc": description,
      "color": color,
      "payKey": payKey
    })

  })
  return resp.json()
}

async function deleteLeaveType(id) {
  const url = process.env.REACT_APP_API + `admin/leave/type?id=${id}`;
  const resp = await fetch(url, {
    method: 'DELETE',
    headers: {
      "Authorization": "Bearer " + getToken(),
      "Content-Type": "application/json"
    }
  });
  return resp.json();
}

export {getLeaveTypes, getLeaveType, createLeaveType, updateLeaveType, deleteLeaveType};