import dateToString from "../Hooks/dateToString";
import {getToken} from "../Hooks/useToken";


async function requestLeave(leave) {
  const url = process.env.REACT_APP_API + 'leave-request';
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + getToken(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({...leave, start: dateToString(leave.start), end: dateToString(leave.end)})
  })

  return resp.json()
}


async function editLeave(leave) {
  const url = process.env.REACT_APP_API + 'admin/leave-request/' + leave.id;
  const resp = await fetch(url, {
    method: 'PUT',
    headers: {
      'Authorization': 'Bearer ' + getToken(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({...leave, start: dateToString(leave.start), end: dateToString(leave.end)})
  })

  return resp.json()
}

async function getAccessibleLeaves({
                                     split = true,
                                     withManager = false,
                                     withOutMe = false,
                                     user = null,
                                     group = null,
                                     start = null,
                                     end = null
                                   })
{
  const url = process.env.REACT_APP_API + 'leave-request/all';
  let data = `?withManager=${withManager}`;
  data += `&withOutMe=${withOutMe}`;
  data += `&split=${split}`;
  data += user ? `&user=${user}` : "";
  data += group ? `&group=${group}` : "";
  start = start ? new Date(start) : null;
  data += start ? `&from=${dateToString(start)}` : "";
  end = end ? new Date(end) : null;
  data += end ? `&to=${dateToString(end)}` : "";

  const resp = await fetch(url + data, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + getToken(),
    },
  })

  if (resp.status === 200) {
    return resp.json();
  }
  return null;
}

async function respondLeave(leaveId, accept, comment) {
  const url = process.env.REACT_APP_API + 'leave-request/' + leaveId + '/response';
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + getToken(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      response: accept ? "approved" : "refused",
      comment: comment || ""})
  })

  return resp.json();
}

async function historyResponse(page, {mine = false, user = null, group = null}){
  let vars = "?mine=" + mine;
  if (user)
    vars += "&user=" + user;
  if (group)
    vars += "&group=" + group;
  const url = process.env.REACT_APP_API + 'leave-request/history/' + page + vars;
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + getToken(),
    }
  })

  return resp.json();
}

async function leaveResponse(page, {user = null, group = null, status = null, manager = false}) {
  let vars = "?";
  if (user)
    vars += "user=" + user;
  if (group) {
    vars += user ? "&" : "";
    vars += "group=" + group;
  }
  if (status) {
    vars += (user || group) ? "&" : "";
    vars += "status=" + status;
  }
  if (manager) {
    vars += (user || group || status) ? "&" : "";
    vars += "manager=" + manager;
  }
  const url = process.env.REACT_APP_API + 'leave-request/page/' + page + vars;
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + getToken(),
    }
  })

  return resp.json();
}

async function cancelLeave(leaveId, comment = "") {
  const url = process.env.REACT_APP_API + 'leave-request/' + leaveId + '/cancel';
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + getToken(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      comment: comment
    })
  })

  return resp.json();
}

async function getLeave(id) {
  const url = process.env.REACT_APP_API + 'leave-request/' + id;
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + getToken(),
    }
  });

  if (resp.status === 200) {
    return resp.json();
  }
}

async function downloadLeaves({month, year}) {
  const url = process.env.REACT_APP_API + 'admin/export/leaves' + `?month=${month}&year=${year}`;
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + getToken(),
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }
  });

  if (!resp.ok) {
    console.error(resp);
  }

  const blob = await resp.blob();
  const urlBlob = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = urlBlob;
  a.download = 'conges.xlsx';
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(urlBlob);
}

export {requestLeave, getAccessibleLeaves, respondLeave, historyResponse, leaveResponse, cancelLeave, getLeave, downloadLeaves, editLeave};