import {useEffect} from "react";

function PageButton(props)
{
  const currentClass = props.current ? "bg-indigo-100 text-indigo-600 border-indigo-300 hover:bg-indigo-200" : 'bg-gray-100 border-gray-300 hover:bg-gray-200';
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      className={currentClass +
        " border px-3 py-2 cursor-pointer h-full" +
        " first:rounded-l-lg last:rounded-r-lg" +
        "  transi" +
        " disabled:text-gray-500 disabled:cursor-not-allowed"}
    >
      {props.children}
    </button>
  )
}

export default function Pagination({ currentPage, totalPage, setPage }) {

  useEffect(() => {
    if (currentPage > totalPage && totalPage > 0) {
      setPage(totalPage);
    }
  }, [currentPage, totalPage]);
  if (totalPage <= 1) return null;
  return (
    <div className={"flex flex-row items-center w-full justify-center"}>
      {currentPage > 2 &&
        <PageButton onClick={() => setPage(1)} disabled={false}>1</PageButton>
      }
      {currentPage > 3 &&
        <PageButton onClick={() => setPage(1)} disabled={true}>...</PageButton>
      }
      {currentPage > 1 &&
        <PageButton onClick={() => setPage(currentPage - 1)} disabled={false}>{currentPage - 1}</PageButton>
      }
      <PageButton current onClick={() => setPage(currentPage)} disabled={false}>{currentPage}</PageButton>
      {currentPage < totalPage &&
        <PageButton onClick={() => setPage(currentPage + 1)} disabled={false}>{currentPage + 1}</PageButton>
      }
      {currentPage < totalPage - 2 &&
        <PageButton onClick={() => setPage(totalPage)} disabled={true}>...</PageButton>
      }
      {currentPage < totalPage - 1 &&
        <PageButton onClick={() => setPage(totalPage)} disabled={false}>{totalPage}</PageButton>
      }
    </div>
  )
}