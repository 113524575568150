import {useEffect, useState} from "react";
import {historyResponse, leaveResponse} from "../../Services/leave";
import LeavesList from "../LeavesList/LeavesList";
import Pagination from "../../Utils/Pagination";
import Select from "../../Utils/Select";
import useMe from "../../Hooks/useMe";

export default function GroupLeaves(props) {
  const {group} = props;
  const user = useMe();
  const [pending, setPending] = useState(null);
  const [history, setHistory] = useState(null);
  const [pagePending, setPagePending] = useState(1);
  const [pageHistory, setPageHistory] = useState(1);
  const [showHistory, setShowHistory] = useState(false);

  function updatePending() {
    if (!group)
      return;
    if (group.managers.filter((manager) => manager.user.id === user.id).length === 0 &&
      !user.roles.includes("ROLE_ADMIN")) {
      return;
    }
    leaveResponse(pagePending,{group: group.id, status: "pending", manager: true}).then((json) => {
      setPending(json || []);
    });
    historyResponse(pageHistory, {group: group.id, manager: true}).then((json) => {
      setHistory(json || []);
    });
  }

  useEffect(() => {
    if (!user)
      return;
    updatePending();
  }, [user, pagePending, pageHistory, group]);

  if (!pending || !history || !user || !group) {
    return null;
  }
  if (group.managers.filter((manager) => manager.user.id === user.id).length === 0 && !user.roles.includes("ROLE_ADMIN")) {
    return null;
  }

  return (
    <>
      <div className={"flex flex-col gap-5"}>
        <h2 className={"text-xl font-medium text-gray-900 px-5 flex items-center justify-between flex-col md:flex-row"}>
          <span>
            {!showHistory ? pending.total || 0 : history.total || 0} congé(s) {showHistory ? "Répondu(s) ou Annulé(s)" : "en attente"}
          </span>
          <Select options={[{label: "En attente", value: false}, {label: "Répondu(s) ou Annulé(s)", value: true}]}
                  value={showHistory} onChange={(e) => setShowHistory(e.target.value)}
                  className={"md:w-80"}/>
        </h2>
        {!showHistory ? <>
          <LeavesList leaves={pending.leaves} config={{auto: true, hide: true, update: updatePending}}/>
          <Pagination currentPage={pagePending} totalPage={Math.ceil(pending.total / 10)}
                      setPage={(page) => setPagePending(page)}/>
        </> : <>
          <LeavesList leaves={history.leaves} config={{auto: user.roles.includes("ROLE_ADMIN"), history: true, update: updatePending}}/>
          <Pagination currentPage={pageHistory} totalPage={Math.ceil(history.total / 10)}
                      setPage={(page) => setPageHistory(page)}/>
        </>}
      </div>
    </>
  );
}