import SideBar from "../../Layouts/SideBar";
import Card from "../../Utils/Card";
import Icon from "../../Utils/Icon";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import Button, {ConfirmButton} from "../../Utils/Button";
import useIsMobile from "../../Hooks/isMobile";
import MorePoints from "../../Utils/MorePoints";
import {deleteUser, getUsers} from "../../Services/user";
import InputText from "../../Utils/InputText";
import Toggle from "../../Utils/Toggle";

function UserDeleteButton(props) {
  return (
    <ConfirmButton variant={"ghost"} icon={props.icon}
                   title={"Êtes-vous sûrs de voulour supprimer cet utilisateur ?"}
                   onClick={() => {
                     deleteUser(props.user.id).then(() => {
                       props.updateUsers();
                     });

                   }}>
      {props.children}
    </ConfirmButton>
  );
}

function rolesToString(roles) {
  if (roles.length === 0) {
    return "Aucun";
  }
  if (roles.length === 1) {
    return roles[0].replaceAll("ROLE_", "");
  }
  // remove USER
  roles = roles.filter((role) => role !== "ROLE_USER");
  return roles.map((role) => role.replaceAll("ROLE_", "")).join(", ");
}

function since(date) {
  const now = new Date();
  const diff = now - new Date(date);
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));

  if (days < 1)
    return "Aujourd'hui";
  if (days < 7)
    return days < 2 ? "Hier" : days + " jours";
  if (days < 30) {
    const weeks = Math.floor(days / 7);
    return weeks + " semaine" + (weeks > 1 ? "s" : "");
  }
  if (days < 365) {
    const months = Math.floor(days / 30);
    return months + " mois";
  }
  const years = Math.floor(days / 365);
  return years + " an" + (years > 1 ? "s" : "");
}

export default function Users() {
  const params = new URLSearchParams(window.location.search);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [usersToDisplay, setUsersToDisplay] = useState([]);
  const [search, setSearch] = useState(params.get("search") || "");
  const [displayInactive, setDisplayInactive] = useState(params.get("inactive") === "true");
  const isMobile = useIsMobile();

  async function updateUsers() {
    const json = await getUsers(displayInactive);
    if (displayInactive) {
      // sort by dateDisabled
      json.sort((a, b) => {
        return new Date(b.dateDisabled) - new Date(a.dateDisabled);
      });
    }
    setUsers(json);
  }

  useEffect(() => {
    updateUsers().then(() => setLoading(false));
  }, [displayInactive]);

  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set("search", search);
    url.searchParams.set("inactive", displayInactive);
    window.history.replaceState({}, "", url);
    setUsersToDisplay(users.filter((user) => {
      const name = (user.fname + " " + user.lname).toLowerCase();
      return name.includes(search.toLowerCase());
    }));
  }, [search, users]);

  return (
    <SideBar>
      <div className={"flex w-full h-full flex-col items-center justify-start gap-8 md:p-8 p-4 text-gray-700"}>
        <div className={"flex w-full gap-8 justify-between pl-20 md:pl-0"}>
          <Card className={"transi lg:px-5 lg:py-3 px-3 py-2 flex items-center"}>
            <h1 className={"lg:text-2xl text-lg font-bold text-gray-700"}>Tous Les Utilisateurs</h1>
          </Card>
          <Link to={"/user/create"}>
            <Card className={"transi lg:px-5 lg:py-3 px-3 py-2 flex items-center gap-2 hover:bg-indigo-100"}>
              <Icon name={"add"} className={"text-3xl"}/>
              <h1 className={"text-xl font-medium hidden sm:block"}>Ajouter un Utilisateur</h1>
            </Card>
          </Link>
        </div>
        <Card className={"transi w-full p-4 gap-4 flex items-center"} rounded={"2xl"}>
          <Icon name={"filter_list"} className={"text-gray-500"}/>
          <div className={"flex gap-4 relative w-96"}>
          <InputText id={"search"} label={"Rechercher"} placeholder={"Rechercher un utilisateur"}
                      value={search} onChange={(e) => setSearch(e.target.value)}
           className={"w-full"}/>
            <Icon name={"search"} className={"absolute right-3 top-1/2 text-gray-500 transform -translate-y-1/2"}/>
          </div>
          <label htmlFor={"toggle"} className={"text-gray-500 ml-1"}>
            {isMobile ? "Utilisateurs inactifs" : "Afficher les utilisateurs inactifs"}
          </label>
          <Toggle id={"toggle"} defaultToggled={displayInactive} onChange={(e) => setDisplayInactive(e.target.value)}/>
        </Card>
        <Card className={"transi w-full overflow-x-auto"} rounded={"2xl"}>
          <table className={"w-full rounded-2xl"}>
            <thead>
            <tr>
              {displayInactive &&
                <th className={"lg:px-6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Désactivé depuis</th>
              }
              <th className={"lg:px-6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Nom
                Prénom
              </th>
              <th className={"lg:px-6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Email
              </th>
              <th className={"lg:px-6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Groupe
              </th>
              <th className={"lg:px-6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Rôle(s)
              </th>
              <th className={"lg:px-6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Actions
              </th>
              {!isMobile &&
                <th
                  className={"lg:px-6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12"}>Supprimer
                </th>}
            </tr>
            </thead>
            <tbody>
            {!loading && usersToDisplay.map((user, index) => (
              <tr key={index} className={"border-b border-gray-200"}>
                {displayInactive &&
                  <td className={"lg:px-6 px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-600"}>
                    {since(user.dateDisabled)}
                  </td>
                }
                <td className={"lg:px-6 px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900"}>
                  <Link to={"/user/" + user.id} className={"underline"}>
                    {user.fname} {user.lname}
                  </Link>
                </td>
                <td className={"lg:px-6 px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-600"}>
                  <a className={"underline"} href={`mailto:${user.email}`}>{user.email}</a>
                </td>
                <td className={"lg:px-6 px-4 py-4 whitespace-nowrap text-sm font-medium " + (user.inGroup ? "text-gray-900" : "text-gray-500")}>
                  {user.inGroup ? user.inGroup.name : "Aucun"}
                </td>
                <td className={"lg:px-6 px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900"}>
                  {rolesToString(user.roles)}
                </td>
                {!isMobile ? (<>
                    <td className={"lg:px-6 px-4 py-4 whitespace-nowrap"}>
                      <Link to={"/user/edit/" + user.id}>
                        <button className={"text-indigo-600"}>Modifier</button>
                      </Link>
                    </td>
                    <td className={"lg:px-6 px-4 py-4 whitespace-nowrap"}>
                      <UserDeleteButton user={user} updateUsers={updateUsers} icon>
                        <Icon name={"delete"}/>
                      </UserDeleteButton>
                    </td>
                  </>) :
                  <td className={"lg:px-6 px-4 py-4 whitespace-nowrap"}>
                    <MorePoints>
                      <Link to={"/user/edit/" + user.id}>
                        <Button variant={"ghost"}>Modifier</Button>
                      </Link>
                      <UserDeleteButton user={user} updateUsers={updateUsers} icon={false}>
                        <span>Supprimer</span>
                      </UserDeleteButton>
                    </MorePoints>
                  </td>}
              </tr>
            ))
            }
            </tbody>
          </table>
        </Card>
      </div>
    </SideBar>
  );
}