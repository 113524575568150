import InputText from "../../Utils/InputText";
import Button from "../../Utils/Button";
import {useEffect, useState} from "react";
import Loading from "../../Utils/Loading";
import SideBar from "../../Layouts/SideBar";
import Card from "../../Utils/Card";
import SelectSearch from "../../Utils/SelectSearch";
import {Link, useParams} from "react-router-dom";
import {getGroups} from "../../Services/group";
import {createUser, getUser, updateUser} from "../../Services/user";
import Toggle from "../../Utils/Toggle";
import Form from "../../Utils/Form";
import Chip from "../../Utils/Chip";
import useUniqueArray from "../../Hooks/useUniqueArray";

function groupToOption(group) {
  return {label: group.name, value: group.id};
}

function roleToOption(role) {
  const toRole = (role) => {
    return "ROLE_" + role.toUpperCase().replaceAll(" ", "_");
  }
  return {label: role, value: toRole(role)};
}

function findGroup(groups, id) {
  return groups.find((group) => group.value === id) || {label: "", value: ""};
}

export default function CreateUser() {
  const myself = JSON.parse(localStorage.getItem("user"));
  const [groups, setGroups] = useState([]);
  const [user, setUser] = useState({lname: "", fname: "", email: "", group: null, password: true, enabled: true});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const rolesList = ["Admin", "User", "Super Manager"].map(roleToOption);
  const [accesses, addAccess, removeAccess, setAccesses] = useUniqueArray();
  const [options, setOptions] = useState([]);
  const {id} = useParams();

  useEffect(() => {
    if (id) {
      getUser(id).then((json) => {
        json["group"] = json.inGroup ? json.inGroup.id : null;
        delete json.inGroup;
        json["roles"] = json.roles.filter((role) => role !== "ROLE_USER");
        json["roles"] = json.roles.filter((role) => role !== "ROLE_MANAGER");
        json["roles"] = json.roles[0] || "ROLE_USER";
        delete json.password;
        setUser(json);
        setAccesses(json.accesses);
      });
    }
    getGroups().then((json) => {
      json = json.map(groupToOption);
      setGroups(json);
    });
  }, []);

  useEffect(() => {
    setOptions(groups.filter((grp) => !accesses.includes(grp.value)));
    setUser({...user, accesses: accesses});
  }, [groups, accesses, user.group]);

  function handleInputChange(event) {
    const newValue = event.target.value;
    setUser({...user, [event.target.id]: newValue});
  }

  async function confirm() {
    setLoading(true);
    let json;
    if (id) {
      json = await updateUser(user);
    } else
      json = await createUser(user);
    if (json) {
      window.location.href = "/user/list";
    } else {
      setLoading(false);
      setError("Il y a eu une erreur lors de la validation.");
    }
  }

  return (
    <SideBar user={myself}>
      {loading ? <Loading page transparent/> : null}
      <div className={"flex w-full h-full flex-col items-center justify-center gap-5"}>
        <Card className={"transi md:min-w-96 p-10"} shadow>
          <h1 className={"text-2xl font-bold mb-6"}>{id ? "Modifier" : "Créer"} un utilisateur</h1>
          <Form className={"flex flex-col lg:flex-row lg:gap-8 gap-2"} onSubmit={confirm}>
            <div className={"flex flex-col lg:gap-8 gap-2 w-full lg:w-1/2"}>
              <div className={"flex flex-col"}>
                <label htmlFor="payId">Matricule</label>
                <InputText type="number" id="payId" value={user.payId} onChange={handleInputChange}/>
              </div>
              <div className={"flex gap-5 flex-row"}>
                <div className={"flex flex-col w-full"}>
                  <label htmlFor="lname">Nom</label>
                  <InputText type="text" id="lname" className={"w-full"} value={user.lname} onChange={handleInputChange}/>
                </div>
                <div className={"flex flex-col w-full"}>
                  <label htmlFor="fname">Prénom</label>
                  <InputText type="text" id="fname" className={"w-full"} value={user.fname} onChange={handleInputChange}/>
                </div>
              </div>
              <div className={"flex flex-col"}>
                <label htmlFor="email">Adresse Email</label>
                <InputText type="email" id="email" value={user.email} onChange={handleInputChange}/>
              </div>
              <div className={"flex flex-col"}>
                <label htmlFor="roles">Rôle</label>
                <SelectSearch id="roles" options={rolesList} value={user.roles} onChange={handleInputChange}
                              placeholder={"User"}/>
              </div>
            </div>
            <div className={"flex flex-col lg:gap-8 gap-2 w-full lg:w-1/2"}>
              <div className={"flex flex-col"}>
                <label htmlFor="group">Groupe</label>
                <SelectSearch id="group" options={groups} value={user.group} onChange={handleInputChange}
                              placeholder={"Aucun"}/>
              </div>
              <div className={"flex flex-col"}>
                <label htmlFor="accesses">Les groupes visiblent supplémentaires</label>
                <div className={"flex flex-wrap px-1 pb-1 gap-1 text-gray-500"}>
                  {accesses && accesses.length !== 0 ? accesses.map((access, index) => (
                    <Chip key={index} label={findGroup(groups, access).label}
                          icon={"close"}
                          iconClick={() => {
                            removeAccess(access);
                          }}/>
                  )) : "Aucun groupe sélectionné"}
                </div>
                <SelectSearch id="accesses" options={options} onChange={(e) => addAccess(e.target.value)} clearValue/>
              </div>
              <div className={"flex justify-between"}>
                <label htmlFor="password">Generer un nouveau mot de passe</label>
                <Toggle id="password"
                        disabled={!id}
                        defaultToggled={id ? user.password === null : true}
                        onChange={handleInputChange}/>
              </div>
              <div className={"flex justify-between"}>
                <label htmlFor="isAutoValidated">Accepter automatiquement les congés</label>
                <Toggle id="isAutoValidated"
                        defaultToggled={user.isAutoValidated ? user.isAutoValidated : false}
                        onChange={handleInputChange}/>
              </div>
              <div className={"flex justify-between"}>
                <label htmlFor="enabled">Compte activé</label>
                <Toggle id="enabled"
                        defaultToggled={id ? user.enabled : true}
                        onChange={handleInputChange}/>
              </div>
              <div className={"flex flex-col"}>
                {error && <p className={"text-red-500 text-center mb-2"}>{error}</p>}
                <Button type="submit" variant={"solid"}>{id ? "Modifier" : "Créer"}</Button>
              </div>
            </div>
          </Form>
        </Card>
        <Link to={"/user/list"} className={"underline text-indigo-500"}>Retourner à la liste des utilisateurs</Link>
      </div>
    </SideBar>
  );
}