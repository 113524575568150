
function Card(props) {
  const rounded = "rounded-" + (props.rounded ? props.rounded : "xl");
  let className = "bg-gray-100 border-gray-300 border hover:border-indigo-300";
  if (props.className)
    className += " " + props.className;
  className += " " + rounded;
  if (props.shadow)
    className += " hover:shadow-2xl hover:shadow-indigo-200";
  return (
    <div className={className}>
      {props.children}
    </div>
  );
}

export default Card;