import SideBar from "../../Layouts/SideBar";
import Card from "../../Utils/Card";
import Icon from "../../Utils/Icon";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import Button, {ConfirmButton} from "../../Utils/Button";
import useIsMobile from "../../Hooks/isMobile";
import MorePoints from "../../Utils/MorePoints";
import {deletePublicHoliday, getPublicHolidays} from "../../Services/publicHolidays";

function HolidayDeleteButton(props) {
  return (
    <ConfirmButton variant={"ghost"} icon={props.icon}
                   title={"Êtes-vous sûrs de voulour supprimer cet utilisateur ?"}
                   onClick={() => {
                     deletePublicHoliday(props.holiday.id).then(() => {
                       props.update();
                     });
                   }}>
      {props.children}
    </ConfirmButton>
  );
}

export default function PublicHolidays() {
  const [holidays, setHolidays] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMobile = useIsMobile();
  async function updateHolidays() {
    const json = await getPublicHolidays();
    setHolidays(json);
  }
  useEffect(() => {

    updateHolidays().then(() => setLoading(false));
  }, []);

  return (
    <SideBar>
      <div className={"flex w-full h-full flex-col items-center justify-start gap-8 md:p-8 p-4 text-gray-700"}>
        <div className={"flex w-full gap-8 justify-between pl-20 md:pl-0"}>
          <Card className={"transi lg:px-5 lg:py-3 px-3 py-2 flex items-center"}>
            <h1 className={"lg:text-2xl text-lg font-bold text-gray-700"}>Tous Jours fériés</h1>
          </Card>
          <Link to={"/holiday/create"}>
            <Card className={"transi lg:px-5 lg:py-3 px-3 py-2 flex items-center gap-2 hover:bg-indigo-100"}>
              <Icon name={"add"} className={"text-3xl"}/>
              <h1 className={"text-xl font-medium hidden sm:block"}>Ajouter jours fériés</h1>
            </Card>
          </Link>
        </div>
        <Card className={"transi w-full overflow-x-auto"} rounded={"2xl"}>
          <table className={"w-full rounded-2xl"}>
            <thead>
            <tr>
              <th className={"lg:px-6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Nom
              </th>
              <th className={"lg:px-6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Date
              </th>
              <th className={"lg:px-6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Se repete
              </th>
              <th className={"lg:px-6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Actions
              </th>
              {!isMobile &&
                <th
                  className={"lg:px-6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Supprimer
                </th>}
            </tr>
            </thead>
            <tbody>
            {!loading && holidays.map((holiday, index) => (
              <tr key={index} className={"border-b border-gray-200"}>
                <td className={"lg:px-6 px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900"}>
                  {holiday.name}
                </td>
                <td className={"lg:px-6 px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900"}>
                  {
                    (holiday.repeat) ?
                      new Date(holiday.date).toLocaleDateString("fr-FR").slice(0, -5) + "/"
                    :
                      new Date(holiday.date).toLocaleDateString("fr-FR")
                  }
                </td>
                <td className={"lg:px-6 px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900"}>
                  {holiday.repeat ? "Oui" : "Non"}
                </td>
                {!isMobile ? (<>
                    <td className={"lg:px-6 px-4 py-4 whitespace-nowrap"}>
                      <Link to={"/holiday/edit/" + holiday.id}>
                        <button className={"text-indigo-600"}>Modifier</button>
                      </Link>
                    </td>
                    <td className={"lg:px-6 px-4 py-4 whitespace-nowrap"}>
                      <HolidayDeleteButton holiday={holiday} update={updateHolidays} icon>
                        <Icon name={"delete"}/>
                      </HolidayDeleteButton>
                    </td>
                  </>) :
                  <td className={"lg:px-6 px-4 py-4 whitespace-nowrap"}>
                    <MorePoints>
                      <Link to={"/holiday/edit/" + holiday.id}>
                        <Button variant={"ghost"}>Modifier</Button>
                      </Link>
                      <HolidayDeleteButton holiday={holiday} update={updateHolidays} icon={false}>
                        <span>Supprimer</span>
                      </HolidayDeleteButton>
                    </MorePoints>
                  </td>}
              </tr>
            ))
            }
            </tbody>
          </table>
        </Card>
      </div>
    </SideBar>
  );
}