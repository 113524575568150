import Card from "../../Utils/Card";
import Button from "../../Utils/Button";
import {Link} from "react-router-dom";
import Icon from "../../Utils/Icon";
import LeavePreview from "../LeavePreview/LeavePreview";
import {useEffect, useState} from "react";


export default function UserFutureLeaves(props) {
  const {user, me} = props;
  const [search, setSearch] = useState(null);

  useEffect(() => {
    setSearch({
      split: false,
      withManager: true,
      withOutMe: false,
      user: user?.id,
      group: null
    });
  }, [user]);

  if (!user)
    return null;

  return (<>
    <Card className={"flex flex-col items-center justify-center px-6 py-5 gap-5"}>
      <div className={"flex w-full justify-between items-center"}>
        <h1 className={"lg:text-2xl text-xl font-bold text-gray-700"}>Mes congés à venir</h1>
        { (!me || me.id === user.id) &&
          <Link to={"/leave/request"}>
          <Button className={"flex items-center"}>
            <Icon name={"add"} className={"mr-2"}/>
            Demander un congé
          </Button>
        </Link>
        }
        { me && me.id !== user.id && me.roles.includes("ROLE_ADMIN") &&
          <Link to={"/leave/request?user=" + user.id}>
          <Button className={"flex items-center"}>
            <Icon name={"add"} className={"mr-2"}/>
            Lui ajouter un congé
          </Button>
        </Link>
        }
      </div>
      <div className={"w-full"}>
        <LeavePreview search={search} users={[user]}/>
      </div>
    </Card>
  </>)
}