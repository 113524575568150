import SideBar from "Layouts/SideBar";
import {useEffect, useState} from "react";
import Card from "Utils/Card";
import UserInformation from "../Components/Profile/UserInformation";
import {mySelf} from "../Services/user";
import UserLeaves from "../Components/Profile/UserLeaves";
import UserFutureLeaves from "../Components/Profile/UserFutureLeaves";
import useMe from "../Hooks/useMe";
import Loading from "../Utils/Loading";

export default function Profile() {
  const user = useMe();

  if (!user)
    return <Loading page/>

  return  (
    <SideBar>
      <div className={"flex w-full h-full flex-col items-center justify-start gap-8 md:p-8 p-4 text-gray-700"}>
        <div className={"flex w-full gap-4 justify-between pl-18 md:pl-0"}>
          <Card className={"transi px-5 py-3 px-3flex items-center"}>
            <h1 className={"lg:text-2xl text-xl font-bold text-gray-700"}>Mon Profil</h1>
          </Card>
        </div>
        <div className={"w-full pb-4 flex flex-col gap-5"}>
          <UserInformation user={user} me/>
          <UserFutureLeaves user={user} />
          <UserLeaves user={user}/>
        </div>
      </div>
    </SideBar>
  );
}