import {isWeekend} from "./UserRow";

function isHoliday(day, holidays) {
  for (let i = 0; i < holidays.length; i++) {
    if (holidays[i].date.getDate() === day.getDate() && holidays[i].date.getMonth() === day.getMonth())
      return true;
  }
  return false;
}

function findHoliday(day, holidays) {
  for (let i = 0; i < holidays.length; i++) {
    if (holidays[i].date.getDate() === day.getDate() && holidays[i].date.getMonth() === day.getMonth())
      return holidays[i];
  }
  return null;
}
export default function LeaveCell(props) {
  const {day, id, hover, setHover, className, holidays} = props;

  let bg = "hover:bg-indigo-200  ";
  if (hover === day.getDate() + " " + day.getMonth())
    bg += " bg-indigo-100";
  if ((day.getDate() > 20 && id < 15) || (day.getDate() < 10 && id > 25))
    bg = "bg-gray-200 hover:bg-gray-300";
  if (isWeekend(day))
    bg = "bg-gray-300 hover:bg-gray-400";
  if (isHoliday(day, holidays))
    bg = "bg-red-300 hover:bg-red-400 relative hover:!text-gray-800";

  return (
    <td key={day} className={className + " border-r border-transparent " + bg}
        onMouseEnter={() => setHover(day.getDate() + " " + day.getMonth())}
        onMouseLeave={() => setHover(null)}
    >
      {props.children}
      {isHoliday(day, holidays) &&
        <div className={"absolute top-[110%] left-1/2 hidden text-sm peer-hover/day:block -translate-x-1/2" +
          " bg-white px-3 py-0.5 rounded-full whitespace-nowrap z-10"}>
          {findHoliday(day, holidays).name}
        </div>
      }
    </td>
  )
}