function isSameDay(day1, day2)
{
  return (day1.getDate() === day2.getDate())
}

function LeaveDayRender({leave, className = ""})
{
  return (
    <div style={{
      backgroundColor: leave.color,
      backgroundSize: "10px 10px",
      backgroundPosition: "2px 2px, 5px 5px",
      backgroundImage: `linear-gradient(135deg, ${leave.color} 12.5%, transparent 12.5%, transparent 37.5%, ${leave.color} 37.5%, ${leave.color} 62.5%, transparent 62.5%, transparent 87.5%, ${leave.color} 87.5%)`
    }} className={
      "h-5 w-full border-spacing-0 relative group " + className
    }>
      <div className={"absolute bottom-[110%] left-1/2 hidden text-sm group-hover:block -translate-x-1/2" +
        " bg-white px-3 py-0.5 rounded-full whitespace-nowrap"}>
        {leave.type.name} {leave.status === "pending" ? "en attente" : ""}
      </div>
    </div>);
}

export default function LeaveDay({day, leaves})
{
  const date = day;
  date.setHours(0, 0, 0, 0);

  leaves = leaves.map((leave) => {
    const start = new Date(leave.start);
    const end = new Date(leave.end);
    let color = leave.type.color;

    if (leave.status === "pending") {
      color += "60";
    }

    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);

    return {...leave, start, end, color};
  });

  leaves = leaves.filter((leave) => {
    return date >= leave.start && date <= leave.end;
  });

  leaves = leaves.sort((a, b) => {
    return a.start - b.start;
  });

  if (leaves.length > 1) {
    return (
      leaves.map((leave, key) =>
        <LeaveDayRender key={key} leave={leave}
                        className={
                          (isSameDay(leave.start, date) ? "rounded-l" : "") +
                          (isSameDay(leave.end, date) ? "rounded-r mr-1 " : "")
                        }/>
      )
    );
  }

  if (leaves.length === 0)
    return null;
  return <LeaveDayRender date={date} leave={leaves[0]}
                         className={
                           (isSameDay(leaves[0].start, date) ? "rounded-l "
                             + (leaves[0].halfDayStart ? "ml-[calc(55%)] " : "ml-1 ") : "")
                           + (isSameDay(leaves[0].end, date) ? "rounded-r "
                             + (leaves[0].halfDayEnd ? "mr-[55%] " : "mr-1 ") : "")}
  />

}
