import InputText from "../../Utils/InputText";
import Button from "../../Utils/Button";
import {useEffect, useState} from "react";
import Loading from "../../Utils/Loading";
import SideBar from "../../Layouts/SideBar";
import Card from "../../Utils/Card";
import SelectSearch from "../../Utils/SelectSearch";
import {getUsers} from "../../Services/user";
import {createGroup, getGroup, updateGroup} from "../../Services/group";
import {Link, useParams} from "react-router-dom";
import Chip from "../../Utils/Chip";
import useAutoChange from "../../Hooks/useAutoChange";
import useUniqueArray from "../../Hooks/useUniqueArray";
import Form from "../../Utils/Form";

function userToOption(user) {
  return {label: user.lname + " " + user.fname, value: user.id, super: user.roles.includes("ROLE_SUPER_MANAGER")};
}

function findUser(users, id) {
  return users.find((user) => user.value === id) || {label: "", value: ""};
}

export default function CreateGroup() {
  const [users, setUsers] = useState([]);
  const [options, setOptions] = useState([]);
  const [managers, addManager, removeManager, setManagers] = useUniqueArray();
  const [supers, addSupers, removeSupers, setSupers] = useUniqueArray();
  const [accesses, addAccess, removeAccess, setAccesses] = useUniqueArray();
  const [group, setGroup] = useState({name: "", managers: managers, accesses: accesses});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const {id} = useParams();
  const handleInputChange = useAutoChange(group, setGroup);

  useEffect(() => {
    if (id) {
      getGroup(id, true).then((json) => {
        json.supers = json.managers.filter((manager) => manager.super).map((manager) => manager.user.id);
        setSupers(json.supers);
        json.managers = json.managers.filter((manager) => !manager.super).map((manager) => manager.user.id);
        setManagers(json.managers);
        json.accesses = json.accesses.map((access) => access.user.id);
        setAccesses(json.accesses);
        setGroup(json);
      });
    }
    getUsers().then((json) => {
      if (!json)
        return;
      json = json.map(userToOption);
      setUsers(json);
    });
  }, []);

  useEffect(() => {
    setOptions(users.filter((user) => !managers.includes(user.value) && !accesses.includes(user.value) && !supers.includes(user.value)));
    setGroup({...group, managers: managers, accesses: accesses, supers: supers});
  }, [users, managers, accesses, supers]);

  async function confirm() {
    setLoading(true)
    const json = id ? await updateGroup(group) : await createGroup(group);

    if (!json) {
      setError("Une erreur est survenue");
      setLoading(false);
      return;
    }
    if (json.message === "ok") {
      window.location.href = "/group/list";
    } else
      setError(json.message);
    setLoading(false);
  }

  return (
    <SideBar>
      {loading ? <Loading page transparent/> : null}
      <div className={"flex w-full h-full flex-col items-center justify-center gap-5"}>
        <Card className={"transi md:min-w-96 p-10"} shadow>
          <h1 className={"text-2xl font-bold mb-6"}>{id ? "Modifier" : "Créer"} un groupe</h1>
          <Form className={"flex flex-col gap-6"} onSubmit={confirm}>
            <div className={"flex flex-col"}>
              <label htmlFor="name">Nom du groupe *</label>
              <InputText type="text" id="name" value={group.name} onChange={handleInputChange}/>
            </div>
            <div className={"flex flex-col"}>
              <label htmlFor="supers">Les Hauts Responsables</label>
              <div className={"flex flex-wrap p-1 gap-1 text-gray-500"}>
                {group.supers && group.supers.length !== 0 ? group.supers.map((supers, index) => (
                  <Chip key={index} label={findUser(users, supers).label}
                        icon={"close"}
                        iconClick={() => {
                          removeSupers(supers);
                        }}/>
                )) : "Aucun haut responsable"}
              </div>
              <SelectSearch id="supers" options={options.filter((option) =>
                option.super
              )} onChange={(e) => addSupers(e.target.value)} clearValue/>
            </div>
            <div className={"flex flex-col"}>
              <label htmlFor="manager">Les Managers</label>
              <div className={"flex flex-wrap p-1 gap-1 text-gray-500"}>
                {group.managers && group.managers.length !== 0 ? group.managers.map((manager, index) => (
                  <Chip key={index} label={findUser(users, manager).label}
                        icon={"close"}
                        iconClick={() => {
                          removeManager(manager);
                        }}/>
                )) : "Aucun manager"}
              </div>
              <SelectSearch id="manager" options={options} onChange={(e) => addManager(e.target.value)} clearValue/>
            </div>
            <div className={"flex flex-col"}>
              <label htmlFor="accesses">Les Utilisateurs en dehors du groupe qui ont access</label>
              <div className={"flex flex-wrap p-1 gap-1 text-gray-500"}>
                {group.accesses && group.accesses.length !== 0 ? group.accesses.map((access, index) => (
                  <Chip key={index} label={findUser(users, access).label}
                        icon={"close"}
                        iconClick={() => {
                          removeAccess(access);
                        }}/>
                )) : "Aucun utilisateur sélectionné"}
              </div>
              <SelectSearch id="accesses" options={options} onChange={(e) => addAccess(e.target.value)} clearValue/>
            </div>
            <div className={"flex flex-col"}>
              {error && <p className={"text-red-500 text-center mb-2"}>{error}</p>}
              <Button type="submit" variant={"solid"}>{id ? "Modifier" : "Créer"}</Button>
            </div>
          </Form>
        </Card>
        <Link to={"/group/list"} className={"underline text-indigo-500"}>Retourner à la liste des groupes</Link>
      </div>
    </SideBar>
  );
}