import {getToken} from "../Hooks/useToken";
import dateToString from "../Hooks/dateToString";

async function getPublicHoliday(id) {
  const response = await fetch(process.env.REACT_APP_API + "holiday/" + id, {
    method: "GET",
    headers: {
      "Authorization": "Bearer " + getToken()
    }
  });

  const json = await response.json();
  if (response.ok) {
    json.date = new Date(json.date);
  }

  return json;
}

async function getPublicHolidays() {
  const response = await fetch(process.env.REACT_APP_API + "holidays", {
    method: "GET",
    headers: {
      "Authorization": "Bearer " + getToken()
    }
  });

  if (!response.ok)
    return null;
  return await response.json();
}

async function createPublicHoliday(data) {
  const response = await fetch(process.env.REACT_APP_API + "admin/holiday", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + getToken()
    },
    body: JSON.stringify({...data, date: dateToString(data.date)})
  });

  return await response.json();
}

async function updatePublicHoliday(data) {

  const response = await fetch(process.env.REACT_APP_API + "admin/holiday", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + getToken()
    },
    body: JSON.stringify({...data, date: dateToString(data.date)})
  });

  return await response.json();
}

async function deletePublicHoliday(id) {
  const response = await fetch(process.env.REACT_APP_API + "admin/holiday/" + id, {
    method: "DELETE",
    headers: {
      "Authorization": "Bearer " + getToken()
    }
  });

  return await response.json();
}

async function getHolidaysBetween(start, end) {
  const response = await fetch(process.env.REACT_APP_API + "holidays/between?start=" + dateToString(start) + "&end=" + dateToString(end), {
    method: "GET",
    headers: {
      "Authorization": "Bearer " + getToken()
    }
  });

  return await response.json();
}

export {getPublicHoliday, getPublicHolidays, createPublicHoliday, updatePublicHoliday, deletePublicHoliday, getHolidaysBetween};