

export default function Form(props) {

  const onSubmit = (e) => {
    e.preventDefault();
    if (props.onSubmit) {
      props.onSubmit();
    }
  }


  return (
    <form className={props.className} onSubmit={onSubmit}>
      {props.children}
    </form>
  );
}