import Button from "../Utils/Button";
import Icon from "../Utils/Icon";
import useToggle from "../Hooks/toggle";
import useIsMobile from "../Hooks/isMobile";
import logo from "../Assets/logo.png";
import {Link} from "react-router-dom";
import useToken from "../Hooks/useToken";
import {useEffect, useRef, useState} from "react";
import {mySelf} from "../Services/user";
import Cookies from "js-cookie";
import {NewPasswordModal} from "../Components/NewPassword";

export default function SideBar(props) {
  const [show, toggleShow] = useToggle(false);
  const isMobile = useIsMobile();
  const {setToken} = useToken();
  const [me, setMe] = useState(Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null);
  const ref = useRef(null);

  function logout() {
    setToken(null);
    window.location.href = "/login";
  }

  useEffect(() => {
    mySelf().then((json) => {
      setMe(json);
      Cookies.set("user", JSON.stringify(json));
    });
  }, []);

  useEffect(() => {
    if (!ref.current)
      return;
    if (ref.current.clientHeight > 30) {
      ref.current.style.fontSize = "1rem";
      ref.current.style.lineHeight = "1.25rem";
    }

  }, [ref.current]);

  return (
    <div className={"flex w-screen"}>
      <div className={"md:hidden z-30 fixed w-full h-full bg-gray-900 bg-opacity-50 transi " + (show ? "" : "hidden")} onClick={toggleShow}></div>
      <div className={"md:sticky z-30 fixed transi md:w-auto h-full flex flex-row " + (isMobile ? (!show ? "-left-full" : "left-0") : "")}>
        <div className={"lg:w-72 overflow-y-auto w-60 h-screen px-3 py-6 flex flex-col border-r border-gray-300 text-md bg-gray-100 hover:border-indigo-300 justify-between"}>
          <div className={"flex flex-col gap-2"}>
            <div className={"flex p-2 gap-2 items-center flex-col lg:flex-row mb-2"}>
              <img src={logo} alt={"logo"} className={"aspect-square w-24"}/>
              <div className={"w-full"}>
                <h1 className={"text-3xl font-bold text-center text-indigo-700 pr-2"}>Zenitime</h1>
                <h2 ref={ref}
                    className={"text-center text-xl font-semibold text-indigo-400"}>{process.env.REACT_APP_COMPANY_NAME}</h2>
              </div>
            </div>
            <Link to={"/profile"}>
              <Button className={"text-left flex items-center py-3 gap-2 !text-gray-600 w-full"} variant={"ghost"}>
                <Icon name={"person"} className={"mr-3"}/>Mon Profil
              </Button>
            </Link>
            <Link to={"/groups"}>
              <Button className={"text-left flex items-center py-3 gap-2 !text-gray-600 w-full"} variant={"ghost"}>
                <Icon name={"group"} className={"mr-3"}/>Mes Services
              </Button>
            </Link>
            {me && me.roles.includes("ROLE_ADMIN") &&
              <Link to={"/config"}>
                <Button className={"text-left flex items-center py-3 gap-2 !text-gray-600 w-full"} variant={"ghost"}>
                  <Icon name={"settings"} className={"mr-3"}/>Gestion
                </Button>
              </Link>}
          </div>
          <Button className={"text-left flex items-center py-3 gap-2 !text-gray-600 w-full"} variant={"ghost"}
                  onClick={logout}>
            <Icon name={"logout"} className={"mr-3"}/>Se déconnecter
          </Button>
        </div>
      </div>
      <div className={"w-full h-screen overflow-auto"}>
        {isMobile && !show &&
          <div className={"fixed top-4 left-4 z-40"}>
            <Button rounded={"full"} className={"p-3 bg-gray-100 aspect-square"} onClick={toggleShow}>
              <Icon name={"menu"} className={"text-2xl"}/>
            </Button>
          </div>}
        <NewPasswordModal show={me && me.newPwd} />
        {props.children}
      </div>
    </div>
  )
}