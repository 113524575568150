import SideBar from "Layouts/SideBar";
import Card from "Utils/Card";
import Icon from "Utils/Icon";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import Button, {ConfirmButton} from "Utils/Button";
import useIsMobile from "Hooks/isMobile";
import MorePoints from "Utils/MorePoints";
import {deleteLeaveType, getLeaveTypes} from "../../../Services/leaveTypes";

function LeaveTypeDeleteButton(props) {
  return (
    <ConfirmButton variant={"ghost"} icon={props.icon}
                   title={"Êtes-vous sûrs de voulour supprimer ce type de congé ?"}
                   onClick={() => {
                     deleteLeaveType(props.leaveType.id).then(() => {
                       props.updateGroups();
                     });

                   }}>
      {props.children}
    </ConfirmButton>
  );
}

export default function Groups() {
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMobile = useIsMobile();
  async function updateLeaveTypes() {
    const json = await getLeaveTypes();
    setLeaveTypes(json);
  }
  useEffect(() => {
    updateLeaveTypes().then(() => setLoading(false));
  }, []);

  return (
    <SideBar>
      <div className={"flex w-full h-full flex-col items-center justify-start gap-8 md:p-8 p-4 text-gray-700"}>
        <div className={"flex w-full gap-8 justify-between pl-20 md:pl-0"}>
          <Card className={"transi lg:px-5 lg:py-3 px-3 py-2 flex items-center"}>
            <h1 className={"lg:text-2xl text-lg font-bold text-gray-700"}>Les Types de congé</h1>
          </Card>
          <Link to={"/leave/type/create"}>
            <Card className={"transi lg:px-5 lg:py-3 px-3 py-2 flex items-center gap-2 hover:bg-indigo-100"}>
              <Icon name={"add"} className={"text-3xl"}/>
              <h1 className={"text-xl font-medium hidden sm:block"}>Ajouter un Type de congé</h1>
            </Card>
          </Link>
        </div>
        <Card className={"transi w-full overflow-x-auto"} rounded={"2xl"}>
          <table className={"w-full rounded-2xl"}>
            <thead>
            <tr>
              <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Nom</th>
              <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Description</th>
              <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Actions
              </th>
              {!isMobile &&
                <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Supprimer
                </th>}
            </tr>
            </thead>
            <tbody>
            {!loading && leaveTypes.map((leaveType, index) => (
              <tr key={index} className={"border-b border-gray-200"}>
                <td className={"px-6 py-4 whitespace-nowrap w-1/6"}>
                  <div className={"text-sm font-medium text-gray-900"}>{leaveType.name}</div>
                </td>
                <td className={"lg:px-6 px-0 py-4 w-3/6 md:4/6"}>
                  <div className={"text-sm font-medium text-gray-500"}>{leaveType.description}</div>
                </td>
                {!isMobile ? (<>
                    <td className={"px-6 py-4"}>
                      <Link to={"/leave/type/edit/" + leaveType.id}>
                        <button className={"text-indigo-600"}>Modifier</button>
                      </Link>
                    </td>
                    <td className={"px-6 py-4 whitespace-nowrap"}>
                      <LeaveTypeDeleteButton leaveType={leaveType} updateGroups={updateLeaveTypes} icon>
                        <Icon name={"delete"}/>
                      </LeaveTypeDeleteButton>
                    </td>
                  </>) :
                  <td className={"px-6 py-4 whitespace-nowrap"}>
                    <MorePoints>
                      <Link to={"/leave/type/edit/" + leaveType.id}>
                        <Button variant={"ghost"}>Modifier</Button>
                      </Link>
                      <LeaveTypeDeleteButton leaveType={leaveType} updateGroups={updateLeaveTypes} icon={false}>
                        <span>Supprimer</span>
                      </LeaveTypeDeleteButton>
                    </MorePoints>
                  </td>}
              </tr>
            ))
            }
            </tbody>
          </table>
        </Card>
      </div>
    </SideBar>
  );
}