import InputText from "./InputText";
import Card from "./Card";
import {useEffect, useRef, useState} from "react";
import Button from "./Button";
import Icon from "./Icon";


export default function MonthSelector({data, setData, before}) {
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const [month, setMonth] = useState(data.month || new Date().getMonth());
  const [year, setYear] = useState(data.year || new Date().getFullYear());
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (!ref.current) return;
      if (!ref.current.contains(event.target)) {
        setShow(false);
      } else {
        setShow(true);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [ref.current]);

  useEffect(() => {
    setData({...data, month: month, year: year});
  }, [month]);

  return (
    <div className={"flex justify-between relative group"} ref={ref}>
      <InputText value={months[month] + " " + year} unchangable/>
      <Card className={(show ? "flex" : "hidden") + " flex-col absolute top-10 right-0 bg-white px-2 py-4 rounded-lg shadow-lg"}>
        <div className={"flex gap-2 flex-row w-full justify-around items-center"}>
          <Button onClick={() => setYear(year - 1)} icon variant={"ghost"}>
            <Icon name={"arrow_back"}/>
          </Button>
          <span className={"text-center"}>{year}</span>
          <Button onClick={() =>  setYear(year + 1)} icon variant={"ghost"} disabled={year >= new Date().getFullYear() && before}
                  className={year >= new Date().getFullYear() && before ? "opacity-50 cursor-not-allowed" : ""}>
            <Icon name={"arrow_forward"}/>
          </Button>
        </div>
        <div className={"flex flex-wrap"}>
          {months.map((m, index) => (
            <div className={"p-1 w-1/3"} key={index}>
            <button disabled={year === new Date().getFullYear() && before && index > new Date().getMonth()}
              onClick={() => {
              setMonth(index);
            }} className={`p-2 rounded-lg text-center w-full ${index === month ? "bg-indigo-100 hover:bg-indigo-200" : "hover:bg-gray-100"} ` +
              `${year === new Date().getFullYear() && before && index > new Date().getMonth() ? "cursor-not-allowed opacity-50" : ""}`
            }>{m}</button>
            </div>
          ))}
        </div>
      </Card>
    </div>
  );

}