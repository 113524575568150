import {useEffect, useState} from "react";
import {historyResponse, leaveResponse} from "../../Services/leave";
import LeavesList from "../LeavesList/LeavesList";
import Pagination from "../../Utils/Pagination";

export default function UserLeaves(props) {
  const {user} = props;
  const [pending, setPending] = useState(null);
  const [history, setHistory] = useState(null);
  const [pagePending, setPagePending] = useState(1);
  const [pageHistory, setPageHistory] = useState(1);

  function updatePending() {
    leaveResponse(pagePending,{user: user.id, status: "pending"}).then((json) => {
      setPending(json || []);
    });
    historyResponse(pageHistory, {user: user.id}).then((json) => {
      setHistory(json || []);
    });
  }

  useEffect(() => {
    if (!user) {
      return;
    }
    updatePending();
  }, [user, pagePending, pageHistory]);

  if (!pending || !history) {
    return null;
  }

  return (
    <>
      <div className={"flex flex-col gap-5"}>
        <h2 className={"text-xl font-medium text-gray-900 px-5"}>
          {pending.total || 0} congé(s) en attente
        </h2>
        <LeavesList leaves={pending.leaves} config={{cancel: true, update: updatePending}}/>
        <Pagination currentPage={pagePending} totalPage={Math.ceil(pending.total / 10)}
                    setPage={(page) => setPagePending(page)}/>
      </div>
      <div className={"flex flex-col gap-5"}>
        <h2 className={"text-xl font-medium text-gray-900 px-5"}>
          {history.total || 0} congé(s) Répondu(s) ou Annulé(s)
        </h2>
        <LeavesList leaves={history.leaves} config={{history: true, update: updatePending}}/>
        <Pagination currentPage={pageHistory} totalPage={Math.ceil(history.total / 10)}
                    setPage={(page) => setPageHistory(page)}/>
      </div>
    </>
  );
}