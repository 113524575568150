import Icon from "./Icon";

export default function Chip({ label, icon, iconClick, onClick, color, className }) {
  className = className || "";
  color = color || "indigo";
  function handleClick(e) {
    if (onClick) {
      onClick(e);
    }
  }

  className += " bg-" + color + "-100 text-" + color + "-800 border border-" + color + "-500";
  className += " flex items-center justify-between px-2 rounded-full text-sm gap-1";
  className += onClick ? " cursor-pointer" : " cursor-default";
  // bg-indigo-100 text-indigo-800 border-indigo-500       Nécaissaire pour que tailwind le compile
  return (
    <div className={className} onClick={handleClick}>
      <span>{label}</span>
      {icon &&
        <button onClick={iconClick} type={"button"}>
          <Icon name={icon} className={"text-sm"}/>
        </button>
      }
    </div>
  );
}