import Card from "../Utils/Card";
import Icon from "../Utils/Icon";
import {useState} from "react";
import Modal from "../Utils/Modal";
import Button from "../Utils/Button";
import MonthSelector from "../Utils/MonthSelector";
import Loading from "../Utils/Loading";
import {downloadLeaves} from "../Services/leave";


export default function ExportLeaves() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({month: new Date().getMonth(), year: new Date().getFullYear()});
  const [loading, setLoading] = useState(false);

  function exportLeaves() {
    setLoading(true);
    const month = data.month + 1;
    const year = data.year;

    downloadLeaves({month, year}).then(() => {
      setLoading(false);
      setOpen(false);
    });
  }

  if (loading)
    return (<Loading page/>)

  return (
    <>
      <button onClick={() => setOpen(true)}>
        <Card className={"transi lg:px-5 lg:py-3 px-3 py-2 flex items-center gap-2 hover:bg-indigo-100"}>
          <Icon name={"download"} className={"text-3xl"}/>
          <h1 className={"text-xl font-medium hidden sm:block"}>Exporter des congés</h1>
        </Card>
      </button>
      <Modal show={open}>
        <Card className={"p-4 flex flex-col gap-6"}>
          <h1 className={"text-xl font-semibold"}>
            Exporter des congés
          </h1>
          <div className={"flex flex-col"}>
            <label htmlFor="month" className={"text-gray-700"}>Sélectionner le mois à exporter</label>
            <MonthSelector data={data} setData={setData} before/>
          </div>
          <div className={"flex gap-4 justify-end"}>
            <Button color={"red"} onClick={() => setOpen(false)}>
              Annuler
            </Button>
            <Button onClick={exportLeaves}>
              Exporter
            </Button>
          </div>
        </Card>
      </Modal>
    </>
  )
}