
import InputText from "./InputText";
import {useEffect, useRef, useState} from "react";
import Icon from "./Icon";
import useInputValue from "../Hooks/useInputValue";
function Option(props) {
  const {label, onClick} = props;

  return (
    <li onClick={onClick} className={"px-3 py-2 rounded hover:bg-indigo-100"}>{label}</li>
  );
}

function filterOptions(option, label, selectedValue) {
  return option.value !== selectedValue;

}
export default function Select(props) {
  const {id, defaultValue, value, onChange, disabled} = props;
  const [inputValue, setInputValue] = useInputValue(value || defaultValue || null, onChange, id);
  const [label, setLabel] = useState("");
  const [options, setOptions] = useState(props.options);
  const ref = useRef();
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current) {
        setShowOptions(ref.current.contains(event.target));
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setOptions(props.options.filter((opt) => filterOptions(opt, label, inputValue)));
    if (value) {
      const opt = props.options.find((opt) => opt.value === value);
      if (opt) {
        setLabel(opt.label);
      }
    }
  }, [inputValue, label, props.options]);
  useEffect(() => {
    setInputValue({target: {value: value, id: id}});
    // find the label in the options and set the value
    const opt = options.find((opt) => opt.value === value);
    if (opt) {
      setLabel(opt.label);
    }
  }, [props.value]);

  const changeLabel = (newLabel) => {
    setLabel(newLabel);
    // find the label in the options and set the value
    const opt = options.find((opt) => opt.label === newLabel);
    if (opt) {
      setInputValue({target: {value: opt.value, id: id}});
    } else {
      setInputValue({target: {value: null, id: id}});
    }
  }

  return (
    <label className={"relative flex flex-col group cursor-pointer"} ref={ref}>
      <InputText type={"button"} className={(props.className || "") + " pr-10 peer/select text-left cursor-pointer"} onChange={(e) => (changeLabel(e.target.value))} value={label} id={id} disabled={disabled} />
      <div className={"absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 peer-focus/select:text-indigo-500"}>
        <Icon name={"arrow_drop_down"} className={""}/>
      </div>
      <ul className={`absolute z-10 top-full left-0 w-full bg-white hover:block border border-indigo-300 rounded-b ` + (showOptions ? "block" : "hidden")}>
        {options.map((opt, index) => <Option key={index} label={opt.label} onClick={(e) => {
          e.preventDefault();
          changeLabel(opt.label);
          setShowOptions(false);
        }}/>)}
      </ul>
    </label>
  );
}