import InputText from "../Utils/InputText";
import Button from "../Utils/Button";
import Card from "../Utils/Card";
import Modal from "../Utils/Modal";
import {useEffect, useState} from "react";
import {newPassword} from "../Services/user";
import Form from "../Utils/Form";

function verifChars(password) {
  return (/^[A-Za-z\d@$!%*?&.]+$/.test(password));
}

function verifyPassword(password) {
  return (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&.]{8,}$/.test(password));
}

export default function NewPassword() {
  const [data, setData] = useState({password: "", passwordConfirm: ""});
  const [error, setError] = useState("");

  function onChange(event) {
    const newValue = event.target.value;
    setData({...data, [event.target.id]: newValue});
  }

  function confirm() {
    if (error !== "") {
      return;
    }

    newPassword(data.password).then((json) => {
      if (json) {
        window.location.reload();
      } else {
        setError("Il y a eu une erreur lors de la validation.");
      }
    });
  }

  useEffect(() => {
    if (data.password === "") {
      setError("");
      return;
    }
    if (!verifChars(data.password)) {
      setError("Votre mot de passe contient des caractères non autorisés");
    } else if (data.password !== data.passwordConfirm && data.passwordConfirm !== "") {
      setError("Les mots de passes ne correspondent pas");
    } else if (!verifyPassword(data.password)) {
      setError("Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule et un chiffre");
    } else {
      setError("");
    }
  }, [data]);

  return (
    <Card className={"flex flex-col gap-4 p-6 md:w-[40em]"}>
      <h2 className={"text-2xl font-bold text-gray-700"}>Veuillez entrer votre nouveau mot de passe</h2>
      <Form className={"flex flex-col gap-2 mt-8"} onSubmit={confirm}>
        <label htmlFor="password">Nouveau mot de passe</label>
        <InputText type={"password"} id={"password"} onChange={onChange}/>
        <label htmlFor="passwordConfirm" className={"mt-4"}>Confirmer le mot de passe</label>
        <InputText type={"password"} id={"passwordConfirm"} onChange={onChange} />
        <p className={"text-red-500 text-center min-h-8"}>{error}</p>
        <Button type={"submit"} className={"bg-green-500 text-white"}>Confirmer</Button>
      </Form>
    </Card>
  )
}

export function NewPasswordModal(props) {
  return (
    <Modal show={props.show}>
      <NewPassword {...props}/>
    </Modal>
  )
}