import Cookies from "js-cookie";

export default function useToken() {
  const token = Cookies.get("token");
  const setToken = (userToken) => {
    if (userToken === null) {
      const cookies = Cookies.get();

      for (const cookie in cookies) {
        Cookies.remove(cookie);
      }
      return;
    }
    Cookies.set("token", userToken, {expires: 1});
  }

  return {token, setToken};
}

function getToken() {
  return Cookies.get("token");
}

export {getToken};