import Card from "../Utils/Card";
import {useEffect, useState} from "react";
import Form from "../Utils/Form";
import InputText from "../Utils/InputText";
import Button from "../Utils/Button";
import {useParams} from "react-router-dom";
import {mySelf, newPassword} from "../Services/user";
import FourOFour from "./404";
import logo from "Assets/logo.png";
import Loading from "../Utils/Loading";

function verifChars(password) {
  return (/^[A-Za-z\d@$!%*?&.]+$/.test(password));
}

function verifyPassword(password) {
  return (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&.]{8,}$/.test(password));
}

export default function LoginForgot() {
  const [newPasswords, setNewPasswords] = useState({password: "", password2: ""});
  const [error, setError] = useState("");
  const {token} = useParams();
  const [loading, setLoading] = useState(false);
  document.title = "Réinitialisation du mot de passe";
  const [tokenError, setTokenError] = useState(false);

  useEffect(() => {
    if (!token) {
      setTokenError(true);
      return;
    }
    mySelf(token).then((json) => {
      if (!json) {
        setTokenError(true);
      }
    });
  }, []);

  function confirm() {
    if (error !== "") {
      return;
    }
    setLoading(true);
    newPassword(newPasswords.password, token).then((json) => {
      if (json) {
        window.location.href = "/login";
      } else {
        setError("Il y a eu une erreur lors de la validation.");
      }
      setLoading(false);
    });
  }
  useEffect(() => {
    if (newPasswords.password === "") {
      setError("");
      return;
    }
    if (!verifChars(newPasswords.password)) {
      setError("Votre mot de passe contient des caractères non autorisés");
    } else if (newPasswords.password !== newPasswords.password2 && newPasswords.password2 !== "") {
      setError("Les mots de passes ne correspondent pas");
    } else if (!verifyPassword(newPasswords.password)) {
      setError("Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule et un chiffre");
    } else {
      setError("");
    }
  }, [newPasswords]);

  if (tokenError) {
    return <FourOFour />
  }
  if (loading) {
    return <Loading page/>
  }
  return <div className={"flex w-screen min-h-screen flex-col items-center justify-center gap-8"}>
    <div className={"flex flex-col items-center"}>
      <img src={logo} className={"w-24 h-24"} alt={"logo"}/>
      <h1 className={"w-full text-center text-4xl font-bold text-indigo-600"}>Zenitime</h1>
    </div>
    <Card className={"flex flex-col items-center gap-4 md:w-[500px]"}>
      <Form className={"flex flex-col p-10 gap-8 md:min-w-96 rounded-xl border bg-gray-50 border-indigo-300 shadow-4xl shadow-indigo-200 w-full transi"}>
        <h1 className={"text-2xl font-bold"}>Réinitialiser le mot de passe</h1>
        <div className={"flex flex-col"}>
          <label htmlFor="password">Nouveau mot de passe</label>
          <InputText type="password" id="password" value={newPasswords.password} onChange={(e) => setNewPasswords({...newPasswords, password: e.target.value})}/>
        </div>
        <div className={"flex flex-col"}>
          <label htmlFor="password2">Confirmer le mot de passe</label>
          <InputText type="password" id="password2" value={newPasswords.password2} onChange={(e) => setNewPasswords({...newPasswords, password2: e.target.value})}/>
        </div>
        <p className={"text-red-600"}>{error}</p>
        <div className={"flex justify-end"}>
          <Button type="submit" className={"w-40 h-12"} onClick={confirm}>Confirmer</Button>
        </div>
      </Form>
    </Card>
    <div className={"h-32"}></div>
  </div>
}