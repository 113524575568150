import SideBar from "../Layouts/SideBar";
import {useEffect, useState} from "react";
import {mySelf} from "../Services/user";
import logo from "../Assets/logo.png";
import {Link} from "react-router-dom";


export default function FourOFour() {
  const [me, setMe] = useState(null);

  useEffect(() => {
    mySelf().then((json) => {
      setMe(json);
    });
  }, []);

  function goBack() {
    window.history.back();
  }

  if (!me)
    return (
      <div className={"flex w-full h-screen flex-col items-center justify-center gap-6 md:px-8 px-4 py-20 text-gray-700"}>
        <img src={logo} alt="logo" className={"w-24"}/>
        <h1 className={"text-4xl font-bold text-indigo-600 mb-20"}>Zenitime</h1>
        <h1 className={"text-3xl font-bold text-gray-700"}>404</h1>
        <h2 className={"text-3xl font-semibold text-gray-700"}>Cette Page n'éxiste pas</h2>
        <Link to={"/login"} className={"text-indigo-600 underline"}>Aller à la page de connexion</Link>
        <div className={"h-64"}/>
      </div>
    );

  return (
    <SideBar>
    <div className={"flex w-full h-full flex-col items-center justify-center gap-6 md:px-8 px-4 py-20 text-gray-700"}>
        <h1 className={"text-3xl font-bold text-gray-700"}>404</h1>
        <h2 className={"text-3xl font-semibold text-gray-700"}>Cette Page n'éxiste pas</h2>
        <p className={"text-gray-70text-center w-[800px] text-lg"}>
          Vous avez peut-être suivi un lien incorrect ou la page a été supprimée.
          <br/>
          Vous pouvez retourner à la page précédente en cliquant sur le bouton ci-dessous ou utiliser les boutons de navigation.
        </p>
        <button className={"text-indigo-600 underline"} onClick={goBack}>Retourner à la page précédente</button>
        <div className={"h-32"}/>
      </div>
    </SideBar>
  );
}