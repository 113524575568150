import SideBar from "../../Layouts/SideBar";
import LeavesList from "../../Components/LeavesList/LeavesList";
import {leaveResponse} from "../../Services/leave";
import {useEffect, useState} from "react";
import Card from "../../Utils/Card";
import SelectSearch from "../../Utils/SelectSearch";
import {getGroupByManager, getGroups} from "../../Services/group";
import {getUsers, getUsersByGroup} from "../../Services/user";
import Select from "../../Utils/Select";
import Button from "../../Utils/Button";
import Pagination from "../../Utils/Pagination";
import ExportLeaves from "../../Components/ExportLeaves";
import useMe from "../../Hooks/useMe";

export default function AllLeaveResponse() {
  const [leaves, setLeaves] = useState([]);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [status] = useState([
    {label: "Tous", value: null},
    {label: "En attente", value: "pending"},
    {label: "Approuvé", value: "approved"},
    {label: "Rejeté", value: "refused"},
    {label: "Annulé", value: "canceled"},
    {label: "Ignoré", value: "ignored"}
  ]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState({user: null, group: null, status: null, page: page});
  const me = useMe();

  const handleInputChange = (e) => {
    const newSearch = {...search};
    newSearch[e.target.id] = e.target.value;

    if (e.target.id === "group" && e.target.value !== null) {
      newSearch.user = undefined;
    }
    if (e.target.id === "user" && e.target.value !== null) {
      newSearch.group = undefined;
    }
    setSearch(newSearch);
  }

  function clearFilters() {
    setSearch({user: undefined, group: undefined, status: null});
  }

  function update() {
    leaveResponse(page, search).then((json) => {
      setLeaves(json || []);
    });
  }

  useEffect(() => {
    update();
  }, [search, page]);

  useEffect(() => {
    if (me.roles.includes("ROLE_ADMIN")) {
      getGroups().then((json) => {
        if (!json) return;
          json = json.map((group) => {
            return {label: group.name, value: group.id};
          });
          setGroups([...json]);
      });
      getUsers().then((json) => {
        if (!json) return;
        json = json.map((user) => {
          return {label: user.fname + " " + user.lname, value: user.id};
        });
        setUsers(json);
      });
    } else {
      getGroupByManager(me.id).then((json) => {
        if (!json) return;
        var groupUsers = [];

        json = json.map((group) => {
          return {label: group.name, value: group.id};
        });
        setGroups([...json]);

        json.forEach(group => {
          getUsersByGroup(group.value, true, false).then((json) => {
            if (!json) return;
            json = json.map((user) => {
              return {label: user.fname + " " + user.lname, value: user.id};
            });
            groupUsers = groupUsers.concat(json);

            // make unique
            groupUsers = groupUsers.filter((group, index, self) =>
              index === self.findIndex((t) => (
                t.label === group.label
              ))
            );
            setUsers(groupUsers);
          })
        });
      });
    }
  }, []);

  return  (
    <SideBar>
      <div className={"flex w-full h-full flex-col items-center justify-start gap-8 md:p-4 lg:p-8 p-4 text-gray-700"}>
        <div className={"flex w-full gap-4 justify-between pl-18 md:pl-0"}>
          <Card className={"transi lg:px-5 lg:py-3 px-3 py-2 flex items-center"}>
            <h1 className={"lg:text-2xl text-lg font-bold text-gray-700"}>Liste des congés</h1>
          </Card>
          <ExportLeaves/>
        </div>
        <div className={"w-full pb-4 flex flex-col gap-5"}>
          <Card className={"flex gap-4 items-end p-4 flex-wrap"}>
            {me.roles.includes("ROLE_ADMIN") &&
            <div>
              <label htmlFor="group" className={"text-gray-700 px-1"}>Filtrer par groupe</label>
              <SelectSearch className={"w-72"} id="group" options={groups} value={search.group}
                            onChange={(e) => {handleInputChange(e);}}
              />
            </div>}
            <div>
              <label htmlFor="user" className={"text-gray-700 px-1"}>Filtrer par utilisateur</label>
              <SelectSearch className={"w-72"} id="user" options={users} value={search.user}
                            onChange={(e) => {handleInputChange(e);}}
              />
            </div>
            <div>
              <label htmlFor="status" className={"text-gray-700 px-1"}>Filtrer par statut</label>
              <Select className={"w-72"} id="status" value={search.status} options={status}
                      onChange={handleInputChange}
              />
            </div>
            <Button className={"w-72 h-[2.6em]"} onClick={clearFilters}>Vider les filtres</Button>
          </Card>
          <h2 className={"text-xl font-medium text-gray-900 px-5"}>
            {leaves.total} congé(s) affiché(s)
          </h2>
          <LeavesList leaves={leaves.leaves} config={{auto: true, toApprove: true, update: update}}/>
          <Pagination currentPage={page} totalPage={Math.ceil(leaves.total / 10)} setPage={(page) => setPage(page)}/>
        </div>
      </div>
    </SideBar>
  );
}