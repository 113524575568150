import InputText, {TextArea} from "Utils/InputText";
import Button from "Utils/Button";
import {useEffect, useState} from "react";
import Loading from "Utils/Loading";
import SideBar from "Layouts/SideBar";
import Card from "Utils/Card";
import {Link, useParams} from "react-router-dom";
import {createLeaveType, getLeaveType, updateLeaveType} from "Services/leaveTypes";
import Form from "../../../Utils/Form";

export default function CreateLeaveType() {
  const [leaveType, setLeaveType] = useState({name: "", description: "", color: "#000000"});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const {id} = useParams();

  useEffect(() => {
    if (id) {
      getLeaveType(id).then((json) => {
        setLeaveType(json);
      });
    }
  }, []);

  function handleInputChange(event) {
    const newValue = event.target.value;
    setLeaveType({...leaveType, [event.target.id]: newValue});
  }

  async function confirm() {
    setLoading(true)
    let json;
    if (id) {
      json = await updateLeaveType(leaveType);
    } else
      json = await createLeaveType(leaveType);

    if (json) {
      if (json.message === "ok")
        window.location.href = "/leave/type/list";
      else {
        setLoading(false);
        setError(json.message);
      }
    }
  }

  return (
    <SideBar>
      {loading ? <Loading page transparent/> : null}
      <div className={"flex w-full h-full flex-col items-center justify-center gap-5"}>
        <Card className={"transi md:min-w-96 p-10"} shadow>
          <h1 className={"text-2xl font-bold mb-6"}>{id ? "Modifier" : "Créer"} un type de congé</h1>
          <Form className={"flex flex-col gap-8"} onSubmit={confirm}>
            <div className={"flex flex-col"}>
              <label htmlFor="name">Nom du type de congé</label>
              <InputText type="text" id="name" value={leaveType.name} onChange={handleInputChange}/>
            </div>
            <div className={"flex flex-col"}>
              <label htmlFor="payKey">Clé</label>
              <InputText type="text" id="payKey" value={leaveType.payKey} onChange={handleInputChange}/>
            </div>
            <div className={"flex flex-col"}>
              <label htmlFor="description">Description</label>
              <TextArea maxLength={255} id="description" value={leaveType.description} onChange={handleInputChange}/>
            </div>
            <div className={"flex flex-col"}>
              <label htmlFor="color">Couleur</label>
              <InputText type="color" id="color" className={"w-full h-12"} value={leaveType.color}
                         onChange={handleInputChange}/>
            </div>
            <div className={"flex flex-col"}>
              {error && <p className={"text-red-500 text-center mb-2"}>{error}</p>}
              <Button type="submit" variant={"solid"}>{id ? "Modifier" : "Créer"}</Button>
            </div>
          </Form>
        </Card>
        <Link to={"/leave/type/list"} className={"underline text-indigo-500"}>Retourner à la liste des types de
          congé</Link>
      </div>
    </SideBar>
  );
}