import SideBar from "../../Layouts/SideBar";
import Card from "../../Utils/Card";
import Icon from "../../Utils/Icon";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {deleteGroup, getGroups} from "../../Services/group";
import Button, {ConfirmButton} from "../../Utils/Button";
import useIsMobile from "../../Hooks/isMobile";
import MorePoints from "../../Utils/MorePoints";

function GroupDeleteButton(props) {
  return (
    <ConfirmButton variant={"ghost"} icon={props.icon}
                   title={"Êtes-vous sûrs de voulour supprimer ce groupe ?"}
                   onClick={() => {
                     deleteGroup(props.group.id).then(() => {
                       props.updateGroups();
                     });

                   }}>
      {props.children}
    </ConfirmButton>
  );
}

export default function Groups() {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMobile = useIsMobile();
  async function updateGroups() {
    const json = await getGroups();
    if (!json)
      return;
    json.forEach((group) => {
      group.managers = group.managers.map((manager) => {
        return manager.user;
      });
    });
    setGroups(json);
  }
  useEffect(() => {

    updateGroups().then(() => setLoading(false));
  }, []);

  return (
    <SideBar>
      <div className={"flex w-full h-full flex-col items-center justify-start gap-8 md:p-8 p-4 text-gray-700"}>
        <div className={"flex w-full gap-8 justify-between pl-20 md:pl-0"}>
          <Card className={"transi lg:px-5 lg:py-3 px-3 py-2 flex items-center"}>
            <h1 className={"lg:text-2xl text-lg font-bold text-gray-700"}>Les Groupes</h1>
          </Card>
          <Link to={"/group/create"}>
            <Card className={"transi lg:px-5 lg:py-3 px-3 py-2 flex items-center gap-2 hover:bg-indigo-100"}>
              <Icon name={"add"} className={"text-3xl"}/>
              <h1 className={"text-xl font-medium hidden sm:block"}>Ajouter un Groupe</h1>
            </Card>
          </Link>
        </div>
        <Card className={"transi w-full overflow-x-auto"} rounded={"2xl"}>
          <table className={"w-full rounded-2xl"}>
            <thead>
            <tr>
              <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Nom</th>
              <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Manager
              </th>
              <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Actions
              </th>
              {!isMobile &&
                <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>Supprimer
                </th>}
            </tr>
            </thead>
            <tbody>
            {!loading && groups.map((group, index) => (
              <tr key={index} className={"border-b border-gray-200"}>
                <td className={"px-6 py-4 whitespace-nowrap w-2/6"}>
                  <Link className={"text-sm font-medium underline"} to={"/group/" + group.id}>{group.name}</Link>
                </td>
                <td className={"px-6 py-4 whitespace-nowrap  w-2/6"}>
                  <div className={"flex gap-x-4 flex-wrap"}>
                    {group.managers.map((manager, index) => (
                      <div className={"text-sm text-gray-900"} key={index}>{manager.lname + " " + manager.fname}</div>
                    ))}
                  </div>
                </td>
                {!isMobile ? (<>
                    <td className={"px-6 py-4 whitespace-nowrap"}>
                      <Link to={"/group/edit/" + group.id}>
                        <button className={"text-indigo-600"}>Modifier</button>
                      </Link>
                    </td>
                    <td className={"px-6 py-4 whitespace-nowrap"}>
                      <GroupDeleteButton group={group} updateGroups={updateGroups} icon>
                        <Icon name={"delete"}/>
                      </GroupDeleteButton>
                    </td></>) :
                  <td className={"px-6 py-4 whitespace-nowrap"}>
                    <MorePoints>
                      <Link to={"/group/edit/" + group.id}>
                        <Button variant={"ghost"}>Modifier</Button>
                      </Link>
                      <GroupDeleteButton group={group} updateGroups={updateGroups} icon={false}>
                        <span>Supprimer</span>
                      </GroupDeleteButton>
                    </MorePoints>
                  </td>}
              </tr>
            ))
            }
            </tbody>
          </table>
        </Card>
      </div>
    </SideBar>
  );
}