import Icon from "./Icon";
import Button from "./Button";
import {useEffect, useRef, useState} from "react";
import Card from "./Card";


function MorePoints(props) {
  const [show, setShow] = useState(false);
  const menuRef = useRef(null);


  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);


  return (
    <div ref={menuRef} className={"relative h-full"}>
      <Button icon variant={"ghost"}  onClick={() => setShow(true)}>
        <Icon name={"more_vert"} className={"w-6 h-6"} />
      </Button>
      {show && <Card className={"absolute bottom-1/2 right-full flex flex-col translate-y-1/2"}>
        {props.children}
      </Card>}
    </div>);
}

export default MorePoints;